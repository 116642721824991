import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-sorteio',
  templateUrl: './sorteio.component.html',
  styleUrls: ['./sorteio.component.scss']
})
export class SorteioComponent implements OnInit {
  sorteios:any = [];
  numSorte:any = "";

  constructor(public app:AppComponent,public db:DataBaseService) { }
  
  ngOnInit(){
    this.numSorte = localStorage.getItem("numSorte");
    this.db.getConfigs().subscribe((z:any) => {
      this.sorteios = z[9].sorteios.sort((a,b) => {if(a.data > b.data)return -1});
    });
  }

  voltarInicio(){
    this.app.router.navigate(['/inicio']);
  }

}
