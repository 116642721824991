import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as moment from 'moment';

@Component({
  selector: 'app-carteirinhas',
  templateUrl: './carteirinhas.component.html',
  styleUrls: ['./carteirinhas.component.scss']
})

export class CarteirinhasComponent implements OnInit {
  cliente:any = [];
  carteirinhas:any = [];
  carteirinhaSelecionada:any = [];
  arrayCarteirinhasSolicitadas:any = [];
  endereco:any = [];
  email:any = "";
  nomeTitular:any = "";
  valorCarteirinhas:number = 12;
  dataHoje:any = "";
  usuario:any = [];
  arrayEmails:any = [];

  constructor(public app:AppComponent,public db:DataBaseService) { }

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let idUser = localStorage.getItem('idUserVM');
    this.carteirinhas = [];
    if(idUser != ''){
      this.db.getAssociadosById(idUser).subscribe((data:any) => {
        this.usuario = data;
        let matriculaGeral = data.matricula;
        let emissaoGeral = data.dataAdesao;
        let planoGeral = data.plano;
        let vencimento = data.dataAdesao;
        this.email = data.email;
        this.nomeTitular = data.nome;
        this.endereco = {
          rua: data.rua,
          bairro: data.bairro,
          complemento: data.complemento,
          numero: data.numero,
          cep: data.cep,
          municipio: data.municipio,
          estado: data.estado
        }
        let objTitular = {
          nome:data.nome,
          plano:planoGeral,
          matricula: matriculaGeral,
          tipo:"TITULAR",
          emissao:emissaoGeral,
          validade:moment(this.dataHoje).add(6,'month').toISOString()
        }
        this.carteirinhas.push(objTitular);
        if(data.dependentes.length > 0){
          data.dependentes.forEach(function(dep){
            let objDependente = {
              nome:dep.nome,
              plano:planoGeral,
              matricula: matriculaGeral,
              tipo:"DEPENDENTE",
              emissao:emissaoGeral,
              validade:moment(this.dataHoje).add(6,'month').toISOString()
            }
            this.carteirinhas.push(objDependente);
          }.bind(this))
        }
        if(data.dependentesExtra.length > 0){
          data.dependentesExtra.forEach(function(depExtra){
            let objDependenteExtra = {
              nome:depExtra.nome,
              plano:planoGeral,
              matricula: matriculaGeral,
              tipo:"DEPENDENTE",
              emissao:emissaoGeral,
              validade:moment(this.dataHoje).add(6,'month').toISOString()
            }
            this.carteirinhas.push(objDependenteExtra);
          }.bind(this))
        }
        this.db.getAllUsuarios().subscribe((userData:any) => {
          this.arrayEmails = userData;
          })
      })
    }else{
       this.db.getServidorById(this.db.idServidor).subscribe((data:any) => {
        this.usuario = data;
        let matriculaGeral = data.matricula;
        let emissaoGeral = data.dataFiliacao;
        let planoGeral = data.plano;
        this.email = data.email;
        this.nomeTitular = data.nome;
        this.endereco = {
          rua: data.rua,
          bairro: data.bairro,
          complemento: data.complemento,
          numero: data.numero,
          cep: data.cep,
          municipio: data.municipio,
          estado: data.estado
        }
        let objTitular = {
          nome:data.nome,
          plano:planoGeral,
          matricula: matriculaGeral,
          tipo:"TITULAR",
          emissao:emissaoGeral,
          validade:moment(this.dataHoje).add(6,'month').toISOString()
        }
        this.carteirinhas.push(objTitular);
        let idxServico:any = data.servicos.map((s:any) => {
          return s.nome;
        }).indexOf("CONVÊNIO DE SAÚDE");
        if(idxServico >= 0){
          if(data.servicos[idxServico].dependentes.length > 0){
            data.servicos[idxServico].dependentes.forEach((dep:any) => {
              let objDependente = {
                nome:dep.nomeCompleto,
                plano:planoGeral,
                matricula: dep.cpf,
                tipo:"DEPENDENTE",
                emissao:emissaoGeral,
                validade:moment(this.dataHoje).add(6,'month').toISOString()
              }
              this.carteirinhas.push(objDependente);
            })
          }
        }
        this.db.getAllUsuarios().subscribe((userData:any) => {
          this.arrayEmails = userData;
          })
      })
    }
   
  }

  virarCartao(x:any){
    let cartao = document.getElementById('card'+x);
    if(cartao.style.transform == 'rotateY(180deg)'){
      cartao.style.transform = 'rotateY(0deg)'
    }else{
      cartao.style.transform = 'rotateY(180deg)'
    }
  }

  baixarCarteirinha(i){
    let element = document.getElementById("carteiraDownload");
    var HTML_Width = element.offsetWidth;
		var HTML_Height = element.offsetHeight;
		var top_left_margin = 15;
		var PDF_Width = HTML_Width+(top_left_margin*2);
		var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
		var canvas_image_width = HTML_Width;
		var canvas_image_height = HTML_Height;
    this.carteirinhaSelecionada = this.carteirinhas[i];
    let nomeArquivo = this.carteirinhaSelecionada.nome.split(' ')[0];
    setTimeout(() => {
      html2canvas(element).then(function(canvas) {
          canvas.toBlob(function(blob){
            var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
            pdf.addImage(canvas, 'PNG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
            pdf.save(nomeArquivo+"-vivamax.pdf");
          },'image/png');
      });
    },1000)
    }

  solicitarCarteirinha(){
      let rightCarteira = document.getElementById('rightCarteira');
      rightCarteira.style.display = 'none';
      let modalCarteira = document.getElementById('solicitarCarteirinha');
      modalCarteira.style.display = "block";
    }

  closeModalCarteirinha(){
    let modalCarteira = document.getElementById('solicitarCarteirinha');
    modalCarteira.style.display = "none";
    let rightCarteira = document.getElementById('rightCarteira');
    rightCarteira.style.display = 'block';
    rightCarteira.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.arrayCarteirinhasSolicitadas = [];
    this.valorCarteirinhas = 12;
    let checks:any = document.getElementsByName('checkBox')
    for (var i = 0; i < checks.length; i++) {
       checks[i].checked = false;
      }
  }
    
  checkCliente(e,c){
    let obj = {};
    let marcado = e.srcElement.checked;
    if(marcado){
      obj = {
        nome: c.nome,
        matricula: c.matricula,
        tipo: c.tipo
      }
      this.arrayCarteirinhasSolicitadas.push(obj);
      this.valorCarteirinhas = this.valorCarteirinhas + 5;
    }else{
      let idx = this.arrayCarteirinhasSolicitadas.map((item) => {
        return item.nome;
      }).indexOf(c.nome);
      if(idx >= 0){
        this.arrayCarteirinhasSolicitadas.splice(idx,1);
        this.valorCarteirinhas = this.valorCarteirinhas - 5;
      }
    }
  }

  pedirCarteirinha(){
    this.db.sendEmailCarteirinha(this.arrayCarteirinhasSolicitadas,this.endereco,this.email,this.db.emailCarteirinhas,this.nomeTitular,this.valorCarteirinhas).subscribe((res:any) => {
      console.log(res);
      this.closeModalCarteirinha();
      alert("Solicitação Enviada Com Suceso!")
     }, err => {
       console.log(err)
       this.closeModalCarteirinha();
       alert("Solicitação Enviada Com Suceso!")
      })
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let indice = this.arrayEmails.map((itemT:any) => {
        return itemT.email.toLowerCase();
      }).indexOf('financeiro1@vivamax.com.br')
      if(indice >= 0){
      let randomInd = Math.random().toString(36).slice(-8);
      let obj = {
        "id": randomInd,
        "data" : this.dataHoje,
        "hora" : hora,
        "titulo" : "DEPENDENTES AREA CLIENTE",
        "descricao" : "Associado: "+ this.usuario.nome + " - Matricula: " + Number(this.usuario.matricula),
        "idResponsavel" : this.arrayEmails[indice]._id,
        "responsavel" : this.arrayEmails[indice].nome,
        "resolvido":false,
        "dataResolucao" : "",
        "horaResolucao" : "",
        "prioridade" : "ALTA",
        "origem":"Sistema",
        "idUserSolicitacao":"",
        "nomeSolicitante":"ÁREA DO CLIENTE"
      }
      this.db.postTarefa(obj).subscribe(resT => {
        console.log(resT)
      }, errT => console.log(errT))
    }
  }

  voltarInicio(){
    if(this.db.idServidor){
      this.app.router.navigate(['/servidor']);
    }else{
      this.app.router.navigate(['/inicio']);
    }
  }

}
