import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-credenciados',
  templateUrl: './credenciados.component.html',
  styleUrls: ['./credenciados.component.scss']
})
export class CredenciadosComponent implements OnInit {
  nomeUsuario:any = "";
  cidade:any = null;
  especialidade:any = null;
  credenciados:any = [];
  cidades:any = [];
  especialidades:any = [];
  procurado:boolean = false;
  cidadeProcurada:any = "";
  especialidadeProcurada:any = "";
  todosCredenciados:any = [];
  nomeCredenciado:any = "";

  constructor(public app:AppComponent,public db: DataBaseService) { }
  
  ngOnInit(){
    this.nomeUsuario = localStorage.getItem('nomeUsuarioVM');
    this.db.getCidadesConfig().subscribe((data:any) => this.cidades = data.cidades);
    this.db.getEspecialidadesConfig().subscribe((data:any) => this.especialidades = data.especialidades);
    this.db.getCredenciados().subscribe((data:any) => { this.credenciados = data;this.todosCredenciados = data;});
  }
  
  search(){
    this.credenciados = [];
    this.procurado = true;
    let cid =  this.cidade == null ? "" :  this.cidade.cidade;
    let esp = this.especialidade == null ? "" : this.especialidade.nome;
    if(cid == "" && esp == ""){
      this.credenciados = this.todosCredenciados;
    }else{
      this.todosCredenciados.forEach((item) => {
        if(item.municipio.match(cid) && item.especialidade.match(esp)){
          let index = this.credenciados.map((x) => {
            return x._id;
            }).indexOf(item._id)
            if(index < 0){
              this.credenciados.push(item)
            }
        }
        if(item.municipio.match(cid) && esp == 'LABORATORIO' && item.laboratorioCheck == true){
          let index = this.credenciados.map((x) => {
            return x._id;
            }).indexOf(item._id)
            if(index < 0){
              this.credenciados.push(item)
            }
        }
        if(item.municipio.match(cid) && item.profissionais.length > 0){
          item.profissionais.forEach((p) => {
            if(p?.especialidade?.match(esp)){
               let index = this.credenciados.map((x) => {
            return x._id;
            }).indexOf(item._id)
            if(index < 0){
              this.credenciados.push(item)
            }
            }
          })
        }
      })
    }
  }

  whatsCredenciado(){
    window.open('https://wa.me/+5508006014235?text=Olá%21%20Eu%20gostaria%20de%20agendar%20uma%20consulta','_blank');
  }
 
  voltarInicio(){
    if(this.db.idServidor){
      this.app.router.navigate(['/servidor']);
    }else{
      this.app.router.navigate(['/inicio']);
    }
  }

  searchPorNome(){
    console.log(this.nomeCredenciado)
    this.credenciados = [];
    this.procurado = true;
    this.credenciados = this.todosCredenciados.filter(item => {
      return item.fantasiaCredenciado?.toLowerCase().indexOf(this.nomeCredenciado.toLowerCase()) > -1;
    });
  }

  diffDays(data){
    let dt = data.split('-').reverse().join('/');
    let date1 = new Date(data);
    let date2 = new Date(new Date(Date.now() - 10800000).toISOString().split('T')[0]);
    let timeDiff = Math.abs(date2.getTime() - date1.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

}