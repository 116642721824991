import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MASKS } from 'ng-brazil';
import { DataBaseService } from '../provider.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { AuthService } from '../auth.service';
import { AppComponent } from '../app.component';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {
  public MASKS = MASKS;
  coberturas:any = [];
  financeiro:any = [];
  cepEditar:any = "";
  ruaEditar:any = "";
  numeroEditar:any = "";
  bairroEditar:any = "";
  municipioEditar:any = "";
  estadoEditar:any = "";
  complementoEditar:any = "";
  telefoneEditar:any = "";
  responsavelEditar:any = "";
  razaoEmpresaEditar:any = "";
  cargoResponsavelEditar:any = "";
  fantasiaEmpresaEditar:any = "";
  cnpjEditar:any = "";
  atividadeEmpresaEditar:any = "";
  nomeContatoEditar:any = "";
  emailEditar:any = "";
  email2Editar:any = "";
  email3Editar:any = "";
  dataContratoEditar:any = "";
  idEmpresa:any = "";
  dataHoje:any = "";
  user:any = "";
  funeralConjugeEditar:any = "";
  funeralFilhosMenorEditar:any = "";
  funeralFilhosMaiorEditar:any = "NÃO";
  funeralExtraEditar:any = "NÃO";
  valorFuneralFilhosMenorEditar:any = null;
  valorFuneralFilhosMaiorEditar:any = null;
  valorFuneralConjugeEditar:any = null;
  valorFuneralExtraEditar:any = null;
  totalAsfDependentesEditar:number = 0;
  planoEditar:any = "";
  valorPlanoEditar:any = null;
  valorSeguroEditar:any = null;
  associadosEmpresa:any = [];
  todosAssociados:any = [];
  boleto:any = "";
  idAssociado:any = "";
  idVendedorNovo:any = "";
  coordenadorNovo:any = "";
  gerenteNovo:any = "";
  dataAdesaoNovo:any = "";
  vendedorNovo:any = "";
  cpfNovo:any = "";
  cpfCheck:boolean = false;
  nascimentoNovo:any = "";
  nomeNovo:any = "";
  estadoCivilNovo:any = "";
  sexoNovo:any = "";
  telefoneNovo:any = "";
  celularNovo:any = "";
  whatsappNovo:any = "";
  cepNovo:any = "";
  ruaNovo:any = "";
  numeroNovo:any = "";
  bairroNovo:any = "";
  complementoNovo:any = "";
  municipioNovo:any = "";
  estadoNovo:any = "";
  funcaoNovo:any = "";
  emailNovo:any = "";
  doencaNovo:any = "";
  especificarDoencaNovo:any = "";
  empresa:any = []; 
  files:any = [];
  selectedFiles:any = [];
  idVendedorEditar:any = "";
  coordenadorEditar:any = "";
  gerenteEditar:any = "";
  vendedorEditar:any = "";
  dependentes:FormGroup;
  dependentesExtra:FormGroup;
  public listaDeDependentes:FormArray;
  public listaDeDependentesExtra:FormArray;
  get dependentesFormGroup(){return this.dependentes.get('dependente') as FormArray;};
  get dependentesExtraFormGroup(){return this.dependentesExtra.get('dependenteExtra') as FormArray;};
  
  constructor(public app: AppComponent,public db:DataBaseService, private formBuilder: FormBuilder, public auth: AuthService){
    if(localStorage.getItem('tipoUsuarioVM') && localStorage.getItem('tipoUsuarioVM') != 'Empresa'){
      this.auth.logOut();
    }
  }

  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  selectTab(tabId: number){
    this.staticTabs.tabs[tabId].active = true;
 }

  ngOnInit(){
    let menuAsso:any = document.getElementById('menuAssociado');
    if(menuAsso){
      menuAsso.style.display = 'none';
    }
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.user = localStorage.getItem('nomeUsuarioVM');
    this.db.getEmpresasById(localStorage.getItem('idUserVM')).subscribe((data:any) => {
      this.empresa = data;
      this.coberturas = this.empresa.coberturas;
      this.boleto = this.empresa.boleto;
      this.idEmpresa = this.empresa._id;
      this.idVendedorNovo = this.empresa.idVendedor;
      this.coordenadorNovo = this.empresa.coordenador;
      this.gerenteNovo = this.empresa.gerente;
      this.vendedorNovo = this.empresa.vendedor;
      this.cepEditar = this.empresa.cep;
      this.ruaEditar = this.empresa.rua;
      this.numeroEditar = this.empresa.numero;
      this.bairroEditar = this.empresa.bairro;
      this.municipioEditar = this.empresa.municipio;
      this.estadoEditar = this.empresa.estado;
      this.complementoEditar = this.empresa.complemento;
      this.telefoneEditar = this.empresa.telefone;
      this.razaoEmpresaEditar = this.empresa.razaoEmpresa;
      this.fantasiaEmpresaEditar = this.empresa.fantasiaEmpresa;
      this.cnpjEditar = this.empresa.cnpj;
      this.planoEditar = this.empresa.plano;
      this.atividadeEmpresaEditar =this. empresa.atividadeEmpresa;
      this.nomeContatoEditar = this.empresa.nomeContato;
      this.emailEditar = this.empresa.email;
      this.email2Editar = this.empresa.email2;
      this.email3Editar = this.empresa.email3;
      this.cargoResponsavelEditar = this.empresa.cargoResponsavel;
      this.funeralConjugeEditar = this.empresa.funeralConjuge;
      this.funeralFilhosMenorEditar = this.empresa.funeralFilhosMenor;
      this.funeralFilhosMaiorEditar = this.empresa.funeralFilhosMaior;
      this.funeralExtraEditar = this.empresa.funeralExtra;
      this.valorFuneralFilhosMenorEditar = this.empresa.valorFuneralFilhosMenor;
      this.valorFuneralFilhosMaiorEditar = this.empresa.valorFuneralFilhosMaior;
      this.valorFuneralConjugeEditar = this.empresa.valorFuneralConjuge;
      this.valorFuneralExtraEditar = this.empresa.valorFuneralExtra;
      this.valorPlanoEditar = this.empresa.valorPlano;
      this.valorSeguroEditar = this.empresa.valorSeguro;   
      this.db.getAssociadosByCodigoEmpresa(this.empresa.codigo).subscribe((data:any) => {
        this.associadosEmpresa = data.sort((a,b) => {if(a.nome < b.nome){return -1}if(a.nome > b.nome){return 1}});
        this.todosAssociados = data;
      })
      this.db.getFinanceiro(this.empresa.razaoEmpresa).subscribe((result:any) => {
        this.financeiro = result.sort((a,b) => {if(a.data > b.data)return -1});
      })
  });
  this.dependentes = this.formBuilder.group({
    dependente: this.formBuilder.array([])
  });
  this.listaDeDependentes = this.dependentes.get('dependente') as FormArray;
  this.dependentesExtra = this.formBuilder.group({
    dependenteExtra: this.formBuilder.array([])
  });
  this.listaDeDependentesExtra = this.dependentesExtra.get('dependenteExtra') as FormArray;
  }

  showTodos(){
    this.associadosEmpresa = this.todosAssociados.sort((a,b) => {if(a.nome < b.nome){return -1}if(a.nome > b.nome){return 1}});;
  }

  showAtivos(){
    this.associadosEmpresa = this.todosAssociados.filter(item => {
      return item.situacao == "ATIVO";
    }).sort((a,b) => {if(a.nome < b.nome){return -1}if(a.nome > b.nome){return 1}});;
  }
  
  showCancelados(){
    this.associadosEmpresa = this.todosAssociados.filter(item => {
      return item.situacao == "CANCELADO";
    }).sort((a,b) => {if(a.nome < b.nome){return -1}if(a.nome > b.nome){return 1}});;
  }
  
  showInadimplentes(){
    this.associadosEmpresa = this.todosAssociados.filter(item => {
      return item.situacao == "INADIMPLENTE";
    }).sort((a,b) => {if(a.nome < b.nome){return -1}if(a.nome > b.nome){return 1}});;
  }
  
createDependente():FormGroup{
  return this.formBuilder.group({
    tipo:[null, Validators.compose([Validators.required])],
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    asf:[null],
    situacao:'ATIVO'
  });
}

addDependente(){
  this.listaDeDependentes.push(this.createDependente());
}

removeDependente(index){
  this.listaDeDependentes.removeAt(index);
}

createDependenteExtra():FormGroup{
  return this.formBuilder.group({
    nome: [null, Validators.compose([Validators.required])],
    nascimento:[null, Validators.compose([Validators.required])],
    cpf: [null],
    tipo:[null, Validators.compose([Validators.required])],
    situacao:'ATIVO'
  });
}

addDependenteExtra(){
  this.listaDeDependentesExtra.push(this.createDependenteExtra());
}

removeDependenteExtra(index){
  this.listaDeDependentesExtra.removeAt(index);
}

  buscarCepEditar(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           alert("CEP NAO ENCONTRADO");
         }else{
           this.ruaEditar = cep.logradouro.toUpperCase();
           this.bairroEditar = cep.bairro.toUpperCase();
           this.municipioEditar = cep.localidade.toUpperCase();
           this.estadoEditar = cep.uf.toUpperCase();
         }
      });
    }
  }

  buscarCepNovo(cepGet){
    let c = cepGet.replace(/_/gi,'').replace('.','').replace('-','');
    if(c.length > 7){
      this.db.getCep(c).subscribe((data:any) => {
        let cep = data;
         if(cep.erro == true){
           alert("CEP NAO ENCONTRADO");
         }else{
           this.ruaNovo = cep.logradouro.toUpperCase();
           this.bairroNovo = cep.bairro.toUpperCase();
           this.municipioNovo = cep.localidade.toUpperCase();
           this.estadoNovo = cep.uf.toUpperCase();
         }
      });
    }
  }

  editarEmpresa(){  
    let dados = {
      cep : this.cepEditar,
      rua : this.ruaEditar,
      numero : this.numeroEditar,
      bairro : this.bairroEditar,
      municipio : this.municipioEditar,
      estado : this.estadoEditar,
      complemento : this.complementoEditar,
      telefone : this.telefoneEditar,
      razaoEmpresa : this.razaoEmpresaEditar,
      fantasiaEmpresa : this.fantasiaEmpresaEditar,
      cnpj : this.cnpjEditar,
      atividadeEmpresa : this.atividadeEmpresaEditar,
      nomeContato : this.nomeContatoEditar,
      email : this.emailEditar,
      email2: this.email2Editar,
      email3: this.email3Editar,
      cargoResponsavel: this.cargoResponsavelEditar,
      funeralConjuge: this.funeralConjugeEditar,
      funeralFilhosMenor: this.funeralFilhosMenorEditar,
      funeralFilhosMaior: this.funeralFilhosMaiorEditar,
      funeralExtra: this.funeralExtraEditar,
      valorFuneralFilhosMenor: this.valorFuneralFilhosMenorEditar,
      valorFuneralFilhosMaior: this.valorFuneralFilhosMaiorEditar,
      valorFuneralConjuge: this.valorFuneralConjugeEditar,
      valorFuneralExtra: this.valorFuneralExtraEditar,
      valorPlano: this.valorPlanoEditar,
      valorSeguro: this.valorSeguroEditar,
      idVendedor : this.idVendedorEditar,
      coordenador : this.coordenadorEditar,
      gerente : this.gerenteEditar,
      vendedor : this.vendedorEditar
    }
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    this.db.patchEmpresa(this.idEmpresa,dados).subscribe(res => {
      console.log(res);
      let body = {
        "menu" : "EMPRESAS", 
        "descricao" : "Empresa "+this.razaoEmpresaEditar+" alterada!", 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(result => {
        console.log(result);
        alert("Empresa Editada com Sucesso!");
    }, errr => console.log(errr));
  }, err => {
    console.log(err);
    alert("Erro ao Editar Empresa, por favor tente novamente!")
  })
}
  
  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B');
  }
  
  reverseReplaceStrings(string:any){
    if(!string){return}
    return string.replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&').replace(/%3B/gi,';');
  }
     
 downloadBoleto(url){
   if(url){
     let splitUrl = url.split('.');
     let extensao = splitUrl[splitUrl.length - 1];
    FileSaver.saveAs(url, "vivamax."+extensao);
   }else{
     alert("Boleto não disponível, por favor entrar em contato com a Viva Max")
   }
}

cancelarAssociado(associado,i){
  if(window.confirm("Deseja realmente Cancelar esse Associado?")){
    this.idAssociado = associado._id;
    let jsonDate = new Date().toJSON();
    let horaAgora = new Date(jsonDate).toLocaleTimeString();
    let body = {
      motivoCancelamento : "MOVIMENTAÇÃO - EXCLUSÃO",
      observacoesCancelamento : "MOVIMENTAÇÃO - EXCLUSÃO",
      spcCancelamento : false,
      situacao : "CANCELADO",
      dataCancelamento: this.dataHoje
    }
    this.db.patchAssociado(this.idAssociado,body).subscribe(res => {
      console.log(res);
      let dados = {
        "data" : this.dataHoje, 
        "hora" : horaAgora, 
        "motivo" : "CANCELAMENTO", 
        "msg" : "MOVIMENTAÇÃO - EXCLUSÃO", 
        "usuario" : this.user
      }
      this.db.newHistorico(this.idAssociado,dados).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let horaAgora = new Date(jsonDate).toLocaleTimeString();
        let body = {
          "menu" : "EMPRESAS", 
          "descricao" : "Cancelamento do Associado: "+associado.nome+" matricula nº "+associado.matricula+", através do menu EMPRESAS", 
          "data" : this.dataHoje, 
          "hora" : horaAgora, 
          "usuario" : this.user
        }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.associadosEmpresa[i].situacao = "CANCELADO";
        this.cancelarDependentes(this.idAssociado);
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err)
    })
  }
}

cancelarDependentes(id){
  this.db.getAssociadosById(id).subscribe((data:any) => {
    let todos = data;
    if(todos.dependentes && todos.dependentes.length > 0){
      let controlDependentes = 1;
      todos.dependentes.forEach((item) => {
        if(item.situacao != "SUSPENSO"){
          item.situacao = "SUSPENSO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentes == todos.dependentes.length){
          let body = {
            dependentes: todos.dependentes
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentes++
        }
      })
    }
    if(todos.dependentesExtra && todos.dependentesExtra.length > 0){
      let controlDependentesExtra = 1;
      todos.dependentesExtra.forEach((item) => {
        if(item.situacao != "SUSPENSO"){
          item.situacao = "SUSPENSO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentesExtra == todos.dependentesExtra.length){
          let body = {
            dependentesExtra: todos.dependentesExtra
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentesExtra++
        }
      })
    }
  })
}

ativarDependentes(id){
  this.db.getAssociadosById(id).subscribe((data:any) => {
    let todos = data;
    if(todos.dependentes && todos.dependentes.length > 0){
      let controlDependentes = 1;
      todos.dependentes.forEach((item) => {
        if(item.situacao != "ATIVO"){
          item.situacao = "ATIVO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentes == todos.dependentes.length){
          let body = {
            dependentes: todos.dependentes
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentes++
        }
      })
    }
    if(todos.dependentesExtra && todos.dependentesExtra.length > 0){
      let controlDependentesExtra = 1;
      todos.dependentesExtra.forEach((item) => {
        if(item.situacao != "ATIVO"){
          item.situacao = "ATIVO"
        }
        if(item.sinistro && item.sinistro.numeroSinistro != null){
          item.sinistro.numeroSinistro = item.sinistro.numeroSinistro
        }
        if(item.sinistro && item.sinistro.valorSinistro != null){
          item.sinistro.valorSinistro = item.sinistro.valorSinistro
        }
        if(controlDependentesExtra == todos.dependentesExtra.length){
          let body = {
            dependentesExtra: todos.dependentesExtra
          }
          this.db.patchAssociado(id,body).subscribe(res => console.log(res), err => console.log(err))
        }else{
          controlDependentesExtra++
        }
      })
    }
  })
}

openModalNovoPj(){
  let modalNew = document.getElementById('modalNovoPjEmpresa');
  modalNew.style.display = "block";
}

fecharModalNovoPj(){
  this.cpfNovo = "";
  this.cpfCheck = null;
  this.estadoCivilNovo = "";
  this.nascimentoNovo = "";
  this.nomeNovo = "";
  this.telefoneNovo = "";
  this.celularNovo = "";
  this.ruaNovo = "";
  this.numeroNovo = "";
  this.complementoNovo = "";
  this.bairroNovo = "";
  this.municipioNovo = "";
  this.estadoNovo = "";
  this.emailNovo = "";
  this.funcaoNovo = "";
  this.doencaNovo = "";
  this.especificarDoencaNovo = "";
  this.whatsappNovo = false;
  this.cepNovo = "";
  this.sexoNovo = "";
  this.listaDeDependentes.clear();
  this.listaDeDependentesExtra.clear();
  this.dataAdesaoNovo = "";
  this.vendedorNovo = "";
  this.files = [];
  let modalNew = document.getElementById('modalNovoPjEmpresa');
  modalNew.style.display = "none";
}

cadastrarAssociadoPj(){
  if(window.confirm("Deseja realmente cadastrar esse Associado?")){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let descr = "Novo Associado: "+this.nomeNovo+" cadastrado na Empresa: "+this.empresa.razaoEmpresa;
    let msg = "ASSOCIADO CADASTRADO!";
    this.db.getConfigs().subscribe((x:any) => {
      let matricula = x[10].matricula;
      let idMatricula = x[10]._id;
      this.db.getAssociadosMatricula(matricula).subscribe((m:any) => {
        if(m && m.length > 0){
          matricula = matricula+10
        }
    let dados = {
      codigoEmpresa:this.empresa.codigo,
      matricula:matricula,
      data: this.dataHoje,
      hora: hora,
      plano : this.empresa.plano,
      cpf : this.cpfNovo,
      estadoCivil : this.estadoCivilNovo,
      nascimento : this.nascimentoNovo.split('/').reverse().join('-'),
      nome : this.nomeNovo,
      sexo: this.sexoNovo,
      telefone : this.telefoneNovo,
      celular : this.celularNovo,
      cep: this.cepNovo,
      rua : this.ruaNovo,
      numero : this.numeroNovo,
      complemento : this.complementoNovo,
      bairro : this.bairroNovo,
      municipio : this.municipioNovo,
      estado : this.estadoNovo,
      email:this.emailNovo,
      localTrabalho :  this.empresa.razaoEmpresa,
      funcao : this.funcaoNovo,
      doenca : this.doencaNovo,
      origem: "INCLUSÃO EMPRESA",
      especificarDoenca :  this.especificarDoencaNovo,
      formaPagamento :  "EMPRESARIAL",
      mensalidade : this.empresa.valorPlano,
      totalMensalidade: (this.empresa.valorPlano + this.totalAsfDependentesEditar),
      taxaAdesao :  0,
      diaVencimento :  "",
      mesFolhaPagamento :  "",
      motivoPendencia : "",
      semAssinatura : false,
      senha:matricula.toString(),
      semAutorizacao : false,
      vendedorEmpresa: this.vendedorEditar,
      historicos:[{
        "data" : this.dataHoje, 
        "hora" : hora, 
        "motivo" : "CADASTRO", 
        "msg" : msg, 
        "usuario" : localStorage.getItem('nomeUsuarioVM') 
       }
      ],
      agendamentos:[],
      dataAdesao:this.dataAdesaoNovo,
      whatsapp: this.whatsappNovo,
      dependentes:this.dependentes.get('dependente').value,
      dependentesExtra:this.dependentesExtra.get('dependenteExtra').value,
      nomeCartao : "",
      numeroCartao : "",
      validadeCartao : "",
      codigoCartao : "",
      idVendedor:this.idVendedorNovo,
      vendedor:this.vendedorNovo,
      subContrato : "",
      planoFisicoCheck: false,
      planoEmpresarialCheck: true,
      cartaoSelecionado: "",
      valorCapitalSegurado: this.empresa.valorSeguro,
      coordenador: this.coordenadorNovo,
      gerente:this.gerenteNovo,
      documentos:[],
      situacao:"ATIVO",
      observacaoPendente : "",
      checkSolicitouCancelamento:false,
      motivoSolicitacaoCancelamento:"",
      empresa:"VIVA MAX"
    }
    this.db.cadastroProposta(dados).subscribe(res => {
      console.log(res);
      if(this.files.length > 0){
        const imagedata = new FormData();
        for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
         this.db.enviarDocAssociado( res['insertedId'], imagedata).subscribe((res) => {
             console.log(res);
           },(error) => {
             console.log(error);
           });
      }
      let body = {
        "menu" : "CADASTRAR ASSOCIADOS EMPRESA", 
        "descricao" : descr, 
        "data" : this.dataHoje, 
        "hora" : hora, 
        "usuario" : this.user
      }
      this.db.postLogs(body).subscribe(res => {
        console.log(res);
        this.db.getAssociadosByCodigoEmpresa(this.empresa.codigo).subscribe((data:any) => {
          this.todosAssociados = data;
          this.associadosEmpresa = data;
          this.db.patchMatricula(idMatricula,{'matricula':matricula+1}).subscribe(res => console.log(res), err => console.log(err));
          this.fecharModalNovoPj();
          alert("Cadastro Realizado com Sucesso");
        })
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
      }, err => {
        console.log(err)
      })
    }, err => {
      console.log(err);
      alert("Não foi possivel cadastrar o Associado, por favor tente novamente!");
    })
  }
}

verificaData(data){
  let ano = data.split('-')[0];
  if(Number(ano) > 9999){
    alert("ATENÇÃO! O ANO NÃO PODE TER MAIS QUE 4 DIGITOS!")
  }
}

cpfUnico(cpf){
  this.cpfCheck = true;
 /*  let key = cpf.replace(/\D/g,"");
  if(key.length == 11){
    this.db.getAssociadosCpf(cpf).subscribe((data:any) => {
      if(data && data.length > 0){
        this.cpfCheck = false;
        alert("ESTE CPF JÁ ESTA CADASTRADO!");
      }else{
        this.cpfCheck = true;
      }
    })
  } */
}

tipoDependentePj(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipoPjNovo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asfPjNovo'+i));
  if(tipoInput == "CÔNJUGE" && this.empresa.funeralConjuge == "AUTOMATICO"){
      asfInput.value = "FUNERAL AUTOMÁTICO VITALÍCIO";
      this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO VITALÍCIO');
  }else{
    asfInput.value = "";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('')
  }
  if((tipoInput == "FILHO" || tipoInput == "FILHA") && this.empresa.funeralFilhosMenor == "AUTOMATICO"){
    asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
 }
}

verificaNascimentoPj(i){
  let tipoInput = (<HTMLInputElement>document.getElementById('tipoPjNovo'+i)).value;
  let asfInput = (<HTMLInputElement>document.getElementById('asfPjNovo'+i));
  let nascimentoInput = (<HTMLInputElement>document.getElementById('nascimentoPjNovo'+i));
  if(tipoInput == "FILHO" || tipoInput == 'FILHA'){
    if(!this.verificaIdade(nascimentoInput.value)){
         asfInput.value = "FUNERAL AUTOMÁTICO ATÉ 18 ANOS";
         asfInput.setAttribute('disabled','true');
         this.dependentes.get('dependente.'+i+'.asf').patchValue('FUNERAL AUTOMÁTICO ATÉ 18 ANOS')
   }else{
    asfInput.value = "NÃO HÁ FUNERAL CONTRATADO";
    this.dependentes.get('dependente.'+i+'.asf').patchValue('NÃO HÁ FUNERAL CONTRATADO');
    asfInput.removeAttribute('disabled');
   }      
 }else{
   return
 }
}

verificaIdade(e){
  if(e){
    var d = new Date;
    let dt = e.split('/').reverse().join('-');
    let data = new Date(dt.split('-')[0],dt.split('-')[1]-1,dt.split('-')[2])
    let ano_atual = d.getFullYear();
    let mes_atual = d.getMonth() + 1;
    let dia_atual = d.getDate();
    let ano_aniversario = data.getFullYear();;
    let mes_aniversario = data.getMonth() + 1;
    let dia_aniversario = data.getDate();
    let idade = ano_atual - ano_aniversario;
    if(mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario){
        idade--;
    }
    if(idade >= 18){
      return true;
    }else{
      return false;
    }
  }else{
    return false;
  }
}

selectDoenca(e){
  let inner = e.srcElement.labels[0].innerText;
  let checked = e.srcElement.checked;
  let c;
  if(inner == "SIM" && checked == true){
      this.doencaNovo = inner;
      c = <HTMLInputElement>document.getElementById('doencaNaoNovo');
      c.checked = false
  }
  if(inner == "NÃO" && checked == true){
      this.doencaNovo = inner;
      c = <HTMLInputElement>document.getElementById('doencaSimNovo');
      c.checked = false
  }
}

onChangeCadastro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

onChangeClickCadastro(event){
  let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  this.selectedFiles = <FileList>event.srcElement.files;
  for(let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
      alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou PDF")
      this.selectedFiles = "";
    }} 
    this.files = [];
    for(let i=0; i<this.selectedFiles.length; i++){
      this.files.push(this.selectedFiles[i].name);
    }
}

deleteDocFiles(idx){
  this.files.splice(idx,1);
  let fileArray = Array.from(this.selectedFiles);
  fileArray.splice(idx,1);
  this.selectedFiles = fileArray;
}

selectAsf(){
  this.totalAsfDependentesEditar = 0;
  if(this.dependentes.value.dependente.length > 0){
      this.dependentes.value.dependente.forEach((item) => {
      if(item.asf == "FUNERAL CONTRATADO"){
        this.totalAsfDependentesEditar += this.valorFuneralFilhosMaiorEditar;
      }
    })
  }
}

}