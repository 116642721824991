<!-- MODAL SOLICITAR CARTEIRINHA -->
<div class="modal" id="solicitarCarteirinha" [ngStyle]="{'padding-left': app.isDesktop ? '20em' : '1em'}">
    <div style="height: 2em;"></div>
    <label style="line-height: 1.5em; text-align: center;">SOLICITAÇÃO DE CARTEIRINHAS VIA FÍSICA</label>
    <div style="height: 2em;"></div>
    <div class="row alertaDependente">
        <div class="col">
            Para solicitação de carteirinhas físicas haverá custo adicional a ser pago em uma parcela para confecção e envio das mesmas.
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <table class="table table-hover">
                <thead>
                    <th>Associado</th>
                    <th>Solicitar</th>
                </thead>
                <tbody>
                    <tr *ngFor="let carteira of carteirinhas">
                        <td style="line-height: 2em;color:#8C949F">{{carteira?.nome}}</td>
                        <td><input class="form-check-input" name="checkBox" type="checkbox" (change)="checkCliente($event, carteira)" style="margin-left:2px"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" *ngIf="arrayCarteirinhasSolicitadas.length > 0" style="text-align: center;">
        <div class="col">
            <p><strong>Valor da Solicitação: {{valorCarteirinhas | currency:'BRL'}}</strong></p>
        </div>
    </div>
    <br>
    <div class="row alertaDependente">
        <div class="col">
            As carteirinhas serão confeccionadas após a confirmação do pagamento do boleto, tendo prazo de 15 dias úteis para envio
        </div>
    </div>
    <br>
    <div class="row" style="text-align: center;">
        <div class="col col-6">
            <button class="btn btn-dark btn-block" (click)="closeModalCarteirinha()">CANCELAR</button>
        </div>
        <div class="col col-6">
            <button class="btn btn-primary" (click)="pedirCarteirinha()" [disabled]="arrayCarteirinhasSolicitadas.length < 1">SOLICITAR</button>
        </div>
    </div>
    <div style="height: 2em;"></div>
</div>
<!-- CARTEIRINHA DOWNLOAD -->
<div id="carteiraDownload">
    <div class="cardImpressao">
        <div class="cartaoImpressao">
            <div class="cartaoImpressaoMargem">
                <div class="cartaoAssociado">
                    CARTÃO DO<br><span style="color:red">ASSOCIADO</span>
                </div>
                <div style="text-align: center;">
                    <img src="../assets/img/logo.png" width="70%">
                </div>
                <br>
                <div class="row nomeCarteirinha">
                    <div class="col">
                        {{carteirinhaSelecionada?.nome}}
                    </div>
                </div>
                <div class="row dadosCarteirinha">
                    <div class="col">
                        EMISSÃO: {{carteirinhaSelecionada?.emissao | date: 'dd/MM/yyyy'}}
                    </div>
                    <div class="col">
                        VALIDADE: {{carteirinhaSelecionada?.validade | date: 'dd/MM/yyyy'}}
                    </div>
                </div>
                <div class="row dadosCarteirinha">
                    <div class="col">
                        TIPO: {{carteirinhaSelecionada?.tipo}}
                    </div>
                    <div class="col">
                        MATRÍCULA: {{carteirinhaSelecionada?.matricula}}
                    </div>
                </div>
            </div>
            <div style="height: 3em"></div>
            <div class="cartaoImpressao">
                <div class="cartaoImpressaoMargem">
                    <div class="row" style="margin-left: 2em;margin-bottom: 5em;">
                        <div class="col col-4" style="text-align: left;font-weight: bold;">
                            vivamax.com.br
                        </div>
                        <div class="col col-8" style="text-align: right;font-weight: bold;">
                            <fa-icon [icon]="['fab', 'facebook']"></fa-icon>&nbsp;<fa-icon [icon]="['fab', 'instagram']"></fa-icon>&nbsp;vivamaxplanofamiliar
                        </div>
                    </div>
                    <div class="row no-gutters aling-items-center" style="text-align: center;margin-left: 2em;margin-bottom: 5em;">
                        <div class="col">
                            <img src="../assets/img/logo.png" width="100%">
                        </div>
                        <div class="col">
                            <img src="../assets/img/sindilojas.png" width="80%">
                        </div>
                    </div>
                    <div class="row aling-items-center" [ngStyle]="{'font-size': !app.isDesktop ? '0.7em' : '1em'}">
                        <div class="col" style="font-weight: 800; margin-bottom: 2em;" [ngStyle]="{'margin-left': app.isDesktop ? '1em' : '3em'}">
                            45.3038-4235 45.99921-0125&nbsp;<fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
                        </div>
                        <div class="col" style="line-height: 1em;text-align: center;font-weight: 600;">
                            R. Marechal Cândido Rondon, 1818<br>
                            Sala 12 - Centro / Cascavel-PR
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}" id="rightCarteira">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <p class="titulo">Carteirinha Digital</p>
        <p class="descricao">Consulte suas carteirinhas baixo!</p>
        <div class="carteirinhas" id="cart" *ngFor="let carteira of carteirinhas; let i = index">
            <div class="cartaoFlip card" id="card{{i}}">
                <div class="cartaoFrente" (click)="virarCartao(i)">
                    <div style="text-align: center;margin-top:1em;margin-bottom: 1em;">
                        <img src="../assets/img/logo.png" width="70%">
                    </div>
                    <br>
                    <div class="row nomeCarteirinha">
                        <div class="col">
                            {{carteira?.nome}}
                        </div>
                    </div>
                    <div class="row dadosCarteirinha">
                        <div class="col">
                            EMISSÃO: {{carteira?.emissao | date: 'dd/MM/yyyy'}}
                        </div>
                        <div class="col">
                            VALIDADE: {{carteira?.validade | date: 'dd/MM/yyyy'}}
                        </div>
                    </div>
                    <div class="row dadosCarteirinha">
                        <div class="col">
                            TIPO: {{carteira?.tipo}}
                        </div>
                        <div class="col">
                            MATRÍCULA: {{carteira?.matricula}}
                        </div>
                    </div>
                </div>
                <div class="cartaoVerso" (click)="virarCartao(i)">
                    <div class="row" style="margin-left: 2em;margin-bottom: 3em;">
                        <div class="col col-4" style="text-align: left;font-weight: bold;">
                            vivamax.com.br
                        </div>
                        <div class="col col-8" style="text-align: right;font-weight: bold;">
                            <fa-icon [icon]="['fab', 'facebook']"></fa-icon>&nbsp;<fa-icon [icon]="['fab', 'instagram']"></fa-icon>&nbsp;vivamaxplanofamiliar
                        </div>
                    </div>
                    <div class="row no-gutters aling-items-center" style="text-align: center;margin-left: 2em;margin-bottom: 5em;">
                        <div class="col">
                            <img src="../assets/img/logo.png" width="100%">
                        </div>
                        <div class="col">
                            <img src="../assets/img/sindilojas.png" width="80%">
                        </div>
                    </div>
                    <div class="row aling-items-center">
                        <div class="col" style="font-weight: 800; margin-bottom: 2em;margin-left:1em">
                            45.3038-4235 45.99921-0125&nbsp;<fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
                        </div>
                        <div class="col" style="line-height: 1em;text-align: center;font-weight: 600;">
                            R. Mal. Cândido Rondon, 1818<br>
                            Sala 12 - Centro / Cascavel-PR
                        </div>
                    </div>
                </div>
            </div>
            <div class="boxAzul" [ngStyle]="{'margin-top':app.isDesktop ? '60px' : '0px'}">
                <label>Cartão do Associado</label>
                <button class="btn btn-light" (click)="baixarCarteirinha(i)">BAIXAR CARTEIRINHA</button>
                <button class="btn btn-light" (click)="solicitarCarteirinha()">SOLICITAR VIA FÍSICA</button>
            </div>
        </div>
    </div>
</div>