import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider.service';
import { locale, loadMessages } from "devextreme/localization";
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {
  pt = {
    "pt": {
        "Yes": "Sim",
        "No": "Não",
        "Cancel": "Cancelar",
        "Clear": "Limpar",
        "Done": "Concluído",
        "Loading": "Carregando ...",
        "Select": "Selecione ...",
        "Search": "Pesquisar ...",
        "Back": "Voltar",
        "OK": "OK",
        "dxScheduler-editorLabelTitle": "Assunto",
        "dxScheduler-editorLabelStartDate": "Data de Início",
        "dxScheduler-editorLabelEndDate": "Data Final",
        "dxScheduler-editorLabelDescription": "Descrição",
        "dxScheduler-editorLabelRecurrence": "Repetir",
        "dxScheduler-openAppointment": "Abrir compromisso",
        "dxScheduler-recurrenceNever": "Nunca",
        "dxScheduler-recurrenceMinutely": "Minutely",
        "dxScheduler-recurrenceHourly": "Hourly",
        "dxScheduler-recurrenceDaily": "Diariamente",
        "dxScheduler-recurrenceWeekly": "Semanalmente",
        "dxScheduler-recurrenceMonthly": "Mensalmente",
        "dxScheduler-recurrenceYearly": "Anualmente",
        "dxScheduler-recurrenceRepeatEvery": "Todos",
        "dxScheduler-recurrenceRepeatOn": "Repeat On",
        "dxScheduler-recurrenceEnd": "Fim da repetição",
        "dxScheduler-recurrenceAfter": "Depois de",
        "dxScheduler-recurrenceOn": "A",
        "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
        "dxScheduler-recurrenceRepeatHourly": "hour(s)",
        "dxScheduler-recurrenceRepeatDaily": "dia(s)",
        "dxScheduler-recurrenceRepeatWeekly": "semana(s)",
        "dxScheduler-recurrenceRepeatMonthly": "mês(es)",
        "dxScheduler-recurrenceRepeatYearly": "ano(s)",
        "dxScheduler-switcherDay": "Dia",
        "dxScheduler-switcherWeek": "Semana",
        "dxScheduler-switcherWorkWeek": "Dias úteis",
        "dxScheduler-switcherMonth": "Mês",
        "dxScheduler-switcherTimelineDay": "Linha de tempo Dia",
        "dxScheduler-switcherTimelineWeek": "Linha de tempo Semana",
        "dxScheduler-switcherTimelineWorkWeek": "Linha de tempo Dias úteis",
        "dxScheduler-switcherTimelineMonth": "Linha de tempo Mês",
        "dxScheduler-switcherAgenda": "Agenda",
        "dxScheduler-recurrenceRepeatOnDate": "na data",
        "dxScheduler-recurrenceRepeatCount": "ocorrência(s)",
        "dxScheduler-allDay": "Todo o dia",
        "dxScheduler-confirmRecurrenceEditMessage": "Deseja editar só este compromisso ou a série toda?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Deseja eliminar só este compromisso ou a série toda?",
        "dxScheduler-confirmRecurrenceEditSeries": "Editar série",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Eliminar série",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Editar compromisso",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Eliminar compromisso",
        "dxScheduler-noTimezoneTitle": "Sem fuso horário",
        "dxScheduler-moreAppointments": "{0} mais"
    }
};
  appointments:any = [];
  dataHojeAgenda:any = [];
  tipoUsuario:any = localStorage.getItem('tipoUsuarioVM');
  usuario:any = [];

  constructor(public app:AppComponent,public db:DataBaseService){
      loadMessages(this.pt);
      locale(navigator.language);
   }

  ngOnInit(): void {
    this.dataHojeAgenda = new Date(Date.now() - 10800000);
    this.appointments = [];
    this.db.getAssociadosById(localStorage.getItem('idUserVM')).subscribe((data:any) =>{
      this.usuario = data;
      switch(this.tipoUsuario){
        case 'Titular': 
          this.appointments = data.agenda;
          break;
        case 'Dependente' :
          let indexDep = data.dependentes.map((itemDep:any) => {
            return itemDep.cpf;
          }).indexOf(localStorage.getItem('cpfVM'))
          if(indexDep >= 0){
            this.appointments = data.dependentes[indexDep].agenda;
            }
          break;
        case 'Dependente Extra' : 
        let indexDepExtra = data.dependentesExtra.map((itemDepExtra:any) => {
          return itemDepExtra.cpf;
        }).indexOf(localStorage.getItem('cpfVM'))
          if(indexDepExtra >= 0){
            this.appointments = data.dependentesExtra[indexDepExtra].agenda;
          }
          break;
        default: break;
      }
    })
  }

  voltarInicio(){
    this.app.router.navigate(['/inicio']);
  }

  replaceStrings(string:any){
    if(!string){return}
    return string.replace(/\%/gi,'%25').replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\;/gi,'%3B');
    }

    onAppointmentFormOpening(e){
        const form = e.form;
        let formItems = form.option("items"); 
        let mainGroupItems = form.itemOption('mainGroup').items;
        mainGroupItems.forEach((item,idx) => {
          if(item.itemType == 'group'){
            item.items.forEach((ii) => {
              if(ii.dataField == 'dayLong' || ii.dataField == 'repeat'){
                mainGroupItems.splice(idx,1)
              }
            })
          }
        })
        if(!mainGroupItems.find(function(i){return i.dataField === "telefone" })){
            mainGroupItems.push({
                colSpan: 2, 
                label: { text: "Telefone" },
                editorType: "dxTextBox",
                dataField: "telefone"
            });
            form.itemOption('mainGroup', 'items', mainGroupItems);
        }
        if(!formItems.find(function(i){return i.dataField === "local"})){
            formItems.push({
                colSpan: 2,
                label: { text: "Local" },
                editorType: "dxTextBox",
                dataField: "local"              
            });
            form.option("items", formItems);
        }   
    }

   addEvento(e){
     let evento = e.appointmentData;
     evento.description = this.replaceStrings(evento.description);
     evento.title = this.replaceStrings(evento.title);
     evento.idUser = localStorage.getItem('idUserVM');
     evento.id = Math.random().toString(32).slice(-8);
     switch(this.tipoUsuario){
      case 'Titular': 
        this.db.postEvento(evento).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));
        break;
      case 'Dependente' :
        this.db.postEventoDependente(this.usuario._id,localStorage.getItem('cpfVM'),evento).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));          
        break;
      case 'Dependente Extra' : 
        this.db.postEventoDependenteExtra(this.usuario._id,localStorage.getItem('cpfVM'),evento).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));          
        break;
      default: break;
    }
  }

   attEvento(e){
    let evento = e.newData;
    let idAgenda = e.newData.id;
    evento.description = this.replaceStrings(evento.description);
    evento.title = this.replaceStrings(evento.title);
    evento.idUser = localStorage.getItem('idUserVM');
      switch(this.tipoUsuario){
        case 'Titular': 
          this.db.patchEvento(evento.idUser,idAgenda,evento).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));
          break;
        case 'Dependente' :
          let indexDep = this.usuario.dependentes.map((itemDep:any) => {
            return itemDep.cpf;
          }).indexOf(localStorage.getItem('cpfVM'))
          if(indexDep >= 0){
            let indiceEvento = this.usuario.dependentes[indexDep].agenda.map((a:any) => {
              return a.id;
            }).indexOf(idAgenda);
            if(indiceEvento >= 0){
              this.db.patchEventoDependente(this.usuario._id,localStorage.getItem('cpfVM'),idAgenda,evento,indiceEvento).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));
            }
          }
          break;
        case 'Dependente Extra' : 
        let indexDepExtra = this.usuario.dependentes.map((itemDepExtra:any) => {
          return itemDepExtra.cpf;
        }).indexOf(localStorage.getItem('cpfVM'))
        if(indexDepExtra >= 0){
          let indiceEventoExtra = this.usuario.dependentesExtra[indexDepExtra].agenda.map((a:any) => {
            return a.id;
          }).indexOf(idAgenda);
          if(indiceEventoExtra >= 0){
            this.db.patchEventoDependenteExtra(this.usuario._id,localStorage.getItem('cpfVM'),idAgenda,evento,indiceEventoExtra).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));
          }
        }
          break;
        default: break;
      }
   } 

   delEvento(e){
    let idAgenda = e.appointmentData.id;
    let idUser = localStorage.getItem('idUserVM');
    switch(this.tipoUsuario){
      case 'Titular': 
        this.db.deleteEvento(idUser,idAgenda).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));
        break;
      case 'Dependente' :
        this.db.deleteEventoDependente(this.usuario._id,localStorage.getItem('cpfVM'),idAgenda).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));
        break;
      case 'Dependente Extra' : 
      this.db.deleteEventoDependenteExtra(this.usuario._id,localStorage.getItem('cpfVM'),idAgenda).subscribe(res => {console.log(res);this.ngOnInit();}, err => console.log(err));
      break;
      default: break;
    }
  }
}
