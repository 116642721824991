import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-telemedicina',
  templateUrl: './telemedicina.component.html',
  styleUrls: ['./telemedicina.component.scss']
})

export class TelemedicinaComponent implements OnInit {
  saudacao:any = "Bom%20dia%20Viva%20Max%21"

  constructor(public app: AppComponent, public db:DataBaseService){}

  ngOnInit(): void {
    let h:any = new Date().toLocaleTimeString('pt-BR', {hour: 'numeric', hour12: false});
    let diaSemana = new Date().getDay();
    if (h >= 0 && h < 12) {
      this.saudacao = "Bom%20Dia%20Viva%20Max%21";
    } else if (h >= 12 && h < 18) {
      this.saudacao = "Boa%20Tarde%20Viva%20Max%21";
    } else if (h >= 18 && h <= 23) {
      this.saudacao = "Boa%20Noite%20Viva%20Max%21";
    }
  }

  voltarInicio(){
    if(this.db.idServidor){
      this.app.router.navigate(['/servidor']);
    }else{
      this.app.router.navigate(['/inicio']);
    }
  }

  callWhats(){
    window.open('https://api.whatsapp.com/send/?phone=558006014235&text='+this.saudacao+'&type=phone_number&app_absent=0','_blank');
  }

}