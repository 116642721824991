import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from './provider.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  matricula:number = null;

  constructor(public db: DataBaseService, public route:Router, public http:HttpClient) {}

loginEmpresa(cnpj, pwd){
  this.db.getLoginByEmpresa(cnpj,pwd).toPromise().then(res => {
      if(res == ''){
        alert("Senha Incorreta!")
        return;
      }else{
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
          let dd = {
            ultimoAcesso: {
              data: new Date(Date.now() - 10800000).toISOString().split('T')[0],
              hora:hora
            }
          }
          this.db.patchEmpresa(res[0]._id,dd).subscribe(result => {
            console.log(result);
            let storage = Math.random().toString(36).slice(-8);
            localStorage.setItem('tokenClienteVivamax', JSON.stringify(storage));
            localStorage.setItem('idUserVM', res[0]._id);
            localStorage.setItem('nomeUsuarioVM', res[0].fantasiaEmpresa);
            localStorage.setItem('codigoEmpresaVM', res[0].codigo);
            localStorage.setItem('tipoUsuarioVM', 'Empresa');
            this.route.navigate(['/empresa']);
          }, err => console.warn(err))
    }
  },
    err => {
      if(err.status < 200){
        alert("Por Favor Verifique Sua Conexão com a Internet")
      }
    }
  ).catch(err => console.log(err))
} 

  logOut() {
    localStorage.clear();
    this.isLoggedIn();
    this.route.navigate(['/login']);
  }

  isLoggedIn(): boolean {
    var token: String = this.getToken();
    return token && token.length > 0;
  }

  getToken(): String {
    var token = localStorage.getItem('tokenClienteVivamax');
    return token ? token : "";
  }

}   