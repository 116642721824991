<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}" id="mainRight">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <br>
        <p class="titulo">  
          Telemedicina
        </p>
        <p class="descricao" style="text-align:justify;padding:5px;">  
          Para ter acesso à telemedicina entre em contato com nossos especialistas através do nosso atendimento exclusivo
        </p>
        <div class="bgConecta">
        </div>
        <div style="margin:0 auto; text-align: center;">
          <button class="btn btn-success btn-block" style="margin:20px;" (click)="callWhats()"><i class="fab fa-whatsapp"></i>&nbsp;Falar com Especialista</button>          
      </div>
    </div>
    <div style="height: 5em;"></div>
</div>