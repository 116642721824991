<!-- MODAL NOVO ASSOCIADO PJ -->
<div class="modalNovoPJ" id="modalNovoPjEmpresa">
    <div class="row align-items-center">
        <div class="col col-12 col-md-3">
            <label>Data Adesão</label>
            <input type="date" (change)="verificaData(dataAdesaoNovo)" class="form-control" [(ngModel)]="dataAdesaoNovo" [ngStyle]="{'border-color': !dataAdesaoNovo ? '#dd443e' : '#3cb42c'}">
        </div>
    </div>        
    <br>
    <div class="row">
        <div class="col col-6 col-md-3">
            <label>CPF:</label>
            <input type="text" class="form-control" [(ngModel)]="cpfNovo" cpf [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': !cpfCheck ? '#dd443e' : '#3cb42c'}" (change)="cpfUnico(cpfNovo)">
        </div>
        <div class="col col-6 col-md-2">
            <label>Nascimento:</label>
            <input type="text" class="form-control" [(ngModel)]="nascimentoNovo" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': nascimentoNovo.length != 10 ? '#dd443e' : '#3cb42c'}">
        </div>
        <div class="col col-12 col-md-4">
            <label>Nome Completo:</label>
            <input type="text" class="form-control" [(ngModel)]="nomeNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeNovo ? '#dd443e' : '#3cb42c'}">
        </div>
        <div class="col col-12 col-md-3">
            <label>Estado Civil:</label>
            <select class="form-control" [(ngModel)]="estadoCivilNovo" [ngStyle]="{'border-color': !estadoCivilNovo ? '#dd443e' : '#3cb42c'}">
                <option value="SOLTEIRO">SOLTEIRO</option>
                <option value="CASADO">CASADO</option>
                <option value="DIVORCIADO">DIVORCIADO</option>
                <option value="VIÚVO">VIÚVO</option>
                <option value="SEPARADO">SEPARADO</option>
                <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
            </select>
        </div>
    </div>
    <br>
    <div [hidden]="nascimentoNovo.length != 10 || !cpfCheck || !nomeNovo || !estadoCivilNovo">
        <div class="row align-items-center">
            <div class="col col-12 col-md-3">
                <label>Sexo:</label>
                <select class="form-control" [(ngModel)]="sexoNovo" [ngStyle]="{'border-color': !sexoNovo ? '#dd443e' : '#3cb42c'}">
                    <option value="MASCULINO">MASCULINO</option>
                    <option value="FEMININO">FEMININO</option>
                </select>
            </div>
            <div class="col col-12 col-md-3">
                <label>Telefone:</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneNovo">
            </div>
            <div class="col col-12 col-md-3">
                <label>Celular:</label>
                <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="celularNovo" [ngStyle]="{'border-color': !celularNovo ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-3">
                <br>
                <div class="form-check" style="margin-left: 1em;">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="whatsappNovo" id="whatsNovo">
                    <label class="form-check-label" for="whatsNovo" style="margin-left: 1em;">&nbsp;&nbsp;Possui Whatsapp</label>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-2">
                <label>CEP:</label>
                <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepNovo" (keyup)="buscarCepNovo(cepNovo)">
            </div>
            <div class="col col-12 col-md-5">
                <label>Rua:</label>
                <input type="text" class="form-control" [(ngModel)]="ruaNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !ruaNovo ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-2">
                <label>Número:</label>
                <input type="text" class="form-control" [(ngModel)]="numeroNovo" [ngStyle]="{'border-color': !numeroNovo ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-3">
                <label>Bairro:</label>
                <input type="text" class="form-control" [(ngModel)]="bairroNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairroNovo ? '#dd443e' : '#3cb42c'}">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-5">
                <label>Complemento:</label>
                <input type="text" class="form-control" [(ngModel)]="complementoNovo" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-12 col-md-5">
                <label>Município:</label>
                <input type="text" class="form-control" [(ngModel)]="municipioNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipioNovo ? '#dd443e' : '#3cb42c'}">
            </div>
            <div class="col col-12 col-md-2">
                <label>UF:</label>
                <input type="text" class="form-control" [(ngModel)]="estadoNovo" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estadoNovo ? '#dd443e' : '#3cb42c'}">
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col col-12 col-md-6">
                <label>Função:</label>
                <input type="text" class="form-control" [(ngModel)]="funcaoNovo" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-12 col-md-6">
                <label>E-mail:</label>
                <input type="text" class="form-control" [(ngModel)]="emailNovo" autocomplete="off"
                (keyup)="app.checkEmail(emailNovo)" oninput="this.value = this.value.toUpperCase()">
                <div class="errorMsg" *ngIf="emailNovo && !app.checkEmail(emailNovo)">E-mail inválido!</div>
            </div>
        </div>
    </div>
    <div [hidden]="!nomeNovo || !estadoCivilNovo || !ruaNovo || !numeroNovo || !bairroNovo || !municipioNovo || !estadoNovo">     
     <br>
        <label>DADOS CADASTRAIS DOS DEPENDENTES</label>
        <br>
        <form [formGroup]="dependentes">
            <div class="row" formArrayName="dependente">
                <div class="form-group col-12 col-md-3" style="text-align: left;" [hidden]="listaDeDependentes?.length > 0">
                    <br>
                    <button class="btn btn-outline-success btn-sm" (click)="addDependente()">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                    </button>
                </div>
                <div class="form-group col-9" [hidden]="listaDeDependentes?.length > 0">
                    &nbsp;
                </div>
                <div class="col col-12" *ngFor="let dep of dependentesFormGroup.controls; let i = index;">
                    <div [formGroupName]="i">
                        <div class="row align-items-center">
                            <div class="form-group col-6 col-md-3">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" id="tipoPjNovo{{i}}" style="text-align: left;" (change)="tipoDependentePj(i)" [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="CÔNJUGE">CÔNJUGE</option>
                                    <option value="FILHO">FILHO</option>
                                    <option value="FILHA">FILHA</option>
                                </select>
                            </div>
                            <div class="form-group col-6 col-md-3">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" formControlName="nascimento" (change)="verificaNascimentoPj(i)" id="nascimentoPjNovo{{i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || (dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null)) ? '#dd443e' : null}">
                            </div> 
                            <div class="form-group col-12 col-md-4">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null || dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col-2" style="text-align: right;" [hidden]="!app.isDesktop">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependente(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependente()" [disabled]="((dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) || (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null)) || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="form-group col-12 col-md-4">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf  id="cpfPjNovo{{i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null) || (dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null)) ? '#dd443e' : null}">
                            </div> 
                            <div class="form-group col-12 col-md-6">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asfPjNovo{{i}}" (change)="selectAsf()">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO" [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE' && empresa.funeralConjuge == 'AUTOMATICO' || dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE' && empresa.funeralConjuge == 'NÃO' ">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO" [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE' && empresa.funeralConjuge == 'AUTOMATICO' || dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE' && empresa.funeralConjuge == 'NÃO'">FUNERAL CONTRATADO</option>
                                </select>
                            </div> 
                            <div class="form-group col-2" [hidden]="!app.isDesktop">
                                &nbsp;
                            </div>
                            <div class="form-group col-12" style="text-align: right;" [hidden]="app.isDesktop">
                                <br>
                                <button class="btn btn-outline-danger btn-sm" (click)="removeDependente(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>&nbsp;&nbsp;Remover
                                </button>&nbsp;
                                <button class="btn btn-outline-success btn-sm" (click)="addDependente()" [disabled]="((dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null) || (dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null)) || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null) || ((dependentes.controls.dependente.value[i].cpf == '' || dependentes.controls.dependente.value[i].cpf == null))))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>&nbsp;&nbsp;Adicionar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </form>
        <br>
        <label>DEPENDENTES EXTRAS</label>
        <form [formGroup]="dependentesExtra">
            <div class="row" formArrayName="dependenteExtra">
                <div class="form-group col-12 col-md-3" style="text-align: left;" [hidden]="listaDeDependentesExtra?.length > 0">
                    <br>
                    <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE EXTRA
                    </button>
                </div>
                <div class="form-group col-9" [hidden]="listaDeDependentesExtra?.length > 0">
                    &nbsp;
                </div>
                <div class="col col-12" *ngFor="let depExtra of dependentesExtraFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row align-items-center">
                        <div class="form-group col-6 col-md-2">
                            <label>Tipo:</label>
                            <select class="form-control" formControlName="tipo" id="tipoPjNovoExtra{{100+i}}" style="text-align: left;" [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                                <option value=""></option>
                                <option value="MÃE">MÃE</option>
                                <option value="PAI">PAI</option>
                                <option value="SOGRO">SOGRO</option>
                                <option value="SOGRA">SOGRA</option>
                            </select>
                        </div>
                         <div class="form-group col-6 col-md-2">
                            <label>Data Nascimento:</label>
                            <input type="text" class="form-control" formControlName="nascimento" id="nascimentoPjNovoExtra{{100+i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null) || (dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null)) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-12 col-md-3">
                            <label>Nome:</label>
                            <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null || dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-12 col-md-3">
                            <label>CPF:</label>
                            <input type="text" class="form-control" formControlName="cpf" cpf  id="cpfPjNovoExtra{{100+i}}" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null)) ? '#dd443e' : null}">
                        </div>
                        <div class="form-group col-2" style="text-align: right;" [hidden]="!app.isDesktop">
                            <br>
                            <button class="btn btn-outline-danger btn-sm" (click)="removeDependenteExtra(i)">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()" [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null))">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                        </div>
                        <div class="form-group col-12" style="text-align: right;" [hidden]="app.isDesktop">
                            <br>
                            <button class="btn btn-outline-danger btn-sm" (click)="removeDependenteExtra(i)">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>&nbsp;&nbsp;Remover
                            </button>&nbsp;
                            <button class="btn btn-outline-success btn-sm" (click)="addDependenteExtra()" [disabled]="((dependentesExtra.controls.dependenteExtra.value[i].tipo == '' || dependentesExtra.controls.dependenteExtra.value[i].tipo == null) || (dependentesExtra.controls.dependenteExtra.value[i].nome == '' || dependentesExtra.controls.dependenteExtra.value[i].nome == null)) || dependentesExtra.controls.dependenteExtra.value[i].asf == 'FUNERAL CONTRATADO' && ((dependentesExtra.controls.dependenteExtra.value[i].cpf == '' || dependentesExtra.controls.dependenteExtra.value[i].cpf == null) || (dependentesExtra.controls.dependenteExtra.value[i].nascimento == '' || dependentesExtra.controls.dependenteExtra.value[i].nascimento == null))">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>&nbsp;&nbsp;Adicionar
                            </button>
                        </div>
                    </div>
                </div>
            </div>   
        </form>
        <br>
        <hr>
        <label>Proponente Possui Problemas Pré Existentes?</label>
        <div style="padding:10px">
            <div class="row">
                <div class="col col-6 col-md-2">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="doencaSimNovo" (change)="selectDoenca($event)">
                        <label class="form-check-label" for="doencaSimNovo" style="margin-left:1em">SIM</label>
                    </div>
                </div>
                <div class="col col-6 col-md-2">
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="doencaNaoNovo" (change)="selectDoenca($event)">
                        <label class="form-check-label" for="doencaNaoNovo" style="margin-left:1em">NÃO</label>
                    </div>
                </div>
                <div class="col col-12 col-md-8" *ngIf="doencaNovo == 'SIM'">
                    <label>Especificar:</label>
                    <input type="text" class="form-control" [(ngModel)]="especificarDoencaNovo" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>     
        </div>
        <div>
            <br><br> 
            <div class="row">
                <div class="col" style="text-align: center; color:#5e5e5e">
                    <h5>Clique ou Arraste para Enviar Documentos ou Fotos</h5>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="uploadfilecontainer" (click)="fileInputNovo.click()" appDragDrop (onFileDropped)="onChangeCadastro($event)">
                        <input hidden type="file" #fileInputNovo (change)="onChangeClickCadastro($event)" multiple>
                    </div>
                    <div class="files-list" *ngFor="let file of files;let i= index">
                        <div class="row align-items-center" style="width: 100%;">
                            <div class="col col-11">
                                <p>{{ file }}</p>
                            </div>
                            <div class="col col-1 del" (click)="deleteDocFiles(i)">
                                X
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <div style="height: 6em;"></div>
    <div class="row" style="padding:10px">
        <div class="col col-6" style="text-align: center;">
            <button class="btn btn-dark" (click)="fecharModalNovoPj()">CANCELAR</button>
        </div>
        <div class="col col-6" style="text-align: center;">
            <button class="btn btn-primary" (click)="cadastrarAssociadoPj()" [disabled]="!cpfNovo || !nascimentoNovo || !estadoCivilNovo || !ruaNovo || !numeroNovo || !bairroNovo || !municipioNovo || !estadoNovo">CADASTRAR</button>
        </div>
    </div>
</div>
<!-- FIM MODAL NOVO ASSOCIADO -->
<div class="topoMenu">
    <img src="../../assets/img/logo.png">           
</div>
<div [ngStyle]="{'padding': !app.isDesktop ? '10px' : '50px'}">
    <div class="row" style="margin-bottom: 2em;align-items: center;">
        <div class="col">
            <label>Olá {{user}}</label>
        </div>
        <div class="col" style="text-align: right;">
            <button class="btn btn-dark" (click)="auth.logOut()">SAIR</button>
        </div>
    </div>
    <tabset #staticTabs>
        <tab heading="DADOS EMPRESA" style="padding:10px;">
            <div class="row">
                <div class="col col-12 col-md-4">
                    <label>Razão Social</label>
                    <input type="text" class="form-control" [(ngModel)]="razaoEmpresaEditar" [ngStyle]="{'border-color': !razaoEmpresaEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-12 col-md-4">
                    <label>Nome Fantasia</label>
                    <input type="text" class="form-control" [(ngModel)]="fantasiaEmpresaEditar" [ngStyle]="{'border-color': !fantasiaEmpresaEditar ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-12 col-md-4">
                    <label>CNPJ</label>
                    <input type="text" class="form-control" cnpj [textMask]="{mask: MASKS.cnpj.textMask}" [(ngModel)]="cnpjEditar" [ngStyle]="{'border-color': !cnpjEditar ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-12 col-md-2">
                    <label>CEP:</label>
                    <input type="text" cep [textMask]="{mask: MASKS.cep.textMask}" class="form-control" [(ngModel)]="cepEditar" (keyup)="buscarCepEditar(cepEditar)">
                </div>
                <div class="col col-12 col-md-5">
                    <label>Rua:</label>
                    <input type="text" class="form-control" [(ngModel)]="ruaEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !ruaEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-4 col-md-2">
                    <label>Número:</label>
                    <input type="text" class="form-control" [(ngModel)]="numeroEditar" [ngStyle]="{'border-color': !numeroEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-8 col-md-3">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" [(ngModel)]="bairroEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !bairroEditar ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-12 col-md-3">
                    <label>Complemento:</label>
                    <input type="text" class="form-control" [(ngModel)]="complementoEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
                <div class="col col-9 col-md-4">
                    <label>Município:</label>
                    <input type="text" class="form-control" [(ngModel)]="municipioEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !municipioEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-3 col-md-1">
                    <label>UF:</label>
                    <input type="text" class="form-control" [(ngModel)]="estadoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !estadoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-4">
                    <label>Atividade da Empresa:</label>
                    <input type="text" class="form-control" [(ngModel)]="atividadeEmpresaEditar" oninput="this.value = this.value.toUpperCase()">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-12 col-md-3">
                    <label>Telefone:</label>
                    <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="telefoneEditar" [ngStyle]="{'border-color': !telefoneEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-4">
                    <label>Responsável:</label>
                    <input type="text" class="form-control" [(ngModel)]="nomeContatoEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nomeContatoEditar ? '#dd443e' : '#3cb42c'}">
                </div>
                <div class="col col-12 col-md-5">
                    <label>Cargo:</label>
                    <input type="text" class="form-control" [(ngModel)]="cargoResponsavelEditar" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !cargoResponsavelEditar ? '#dd443e' : '#3cb42c'}">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-12 col-md-4">
                    <label>E-mail Principal:</label>
                    <input type="text" class="form-control" [(ngModel)]="emailEditar" autocomplete="off"
                    (keyup)="app.checkEmail(emailEditar)" oninput="this.value = this.value.toUpperCase()">
                    <div class="errorMsg" *ngIf="emailEditar && !app.checkEmail(emailEditar)">E-mail inválido!</div>
                </div>
                <div class="col col-12 col-md-4">
                    <label>E-mail 2:</label>
                    <input type="text" class="form-control" [(ngModel)]="email2Editar" autocomplete="off"
                    (keyup)="app.checkEmail(email2Editar)" oninput="this.value = this.value.toUpperCase()">
                    <div class="errorMsg" *ngIf="email2Editar && !app.checkEmail(email2Editar)">E-mail inválido!</div>
                </div>
                <div class="col col-12 col-md-4">
                    <label>E-mail 3:</label>
                    <input type="text" class="form-control" [(ngModel)]="email3Editar" autocomplete="off"
                    (keyup)="app.checkEmail(email3Editar)" oninput="this.value = this.value.toUpperCase()">
                    <div class="errorMsg" *ngIf="email3Editar && !app.checkEmail(email3Editar)">E-mail inválido!</div>
                </div>
            </div>
            <br>
            <label>DADOS DO PLANO:</label>
            <br>
            <div class="row">
                <div class="col col-12 col-md-12">
                    <label>Plano:</label>
                    <input type="text" class="form-control" [(ngModel)]="planoEditar" readonly>
                </div>
            </div>
            <br>
            <div class="row" *ngIf="planoEditar == 'PERSONALIZADO'">
                <div class="col col-12 col-md-12">
                    <label>Coberturas do Seguro:</label>
                    <br><br>
                    <div class="table-responsive">
                        <table class="table striped">
                            <tr *ngFor="let cob of coberturas">
                                <td style="text-align:left;font-weight: bold;">{{cob.cobertura}}</td>
                                <td style="text-align:left;font-weight: bold;">{{cob.capital | currency: 'BRL'}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="planoEditar == 'PERSONALIZADO'">
                <div class="col col-12 col-md-4">
                    <label>Funeral Cônjuge:</label>
                    <select class="form-control" [(ngModel)]="funeralConjugeEditar" readonly style="pointer-events: none;">
                        <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                    </select>
                </div>
                <div class="col col-6 col-md-2" *ngIf="funeralConjugeEditar == 'SIM'">
                    <label>Valor:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon2">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralConjugeEditar" readonly>
                    </div>
                </div>
                <div class="col col-12 col-md-4">
                    <label>Funeral Filhos Menor 18:</label>
                    <select class="form-control" [(ngModel)]="funeralFilhosMenorEditar" readonly style="pointer-events: none;">
                        <option value="AUTOMATICO">AUTOMÁTICO SEM CUSTO</option>
                        <option value="SIM">SIM</option>
                        <option value="NÃO">NÃO</option>
                    </select>
                </div>
                <div class="col col-6 col-md-2" *ngIf="funeralFilhosMenorEditar == 'SIM'">
                    <label>Valor:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon3">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorFuneralFilhosMenorEditar" readonly>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="planoEditar == 'PERSONALIZADO'">
                <div class="col col-6 col-md-4">
                    <label>Valor do Plano:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon4">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorPlanoEditar" readonly>
                    </div>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Valor do Seguro:</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">R$</span>
                        </div>
                        <input type="number" min=0 class="form-control" [(ngModel)]="valorSeguroEditar" readonly>
                    </div>
                </div>
            </div>
            <div class="row" style="margin: 20px; text-align: center;">
                <div class="col">
                        <button class="btn btn-primary" (click)="editarEmpresa()">EDITAR DADOS</button>
                </div>
            </div>
        </tab>
        <tab heading="COLABORADORES" style="padding:10px">
            <div class="row" style="place-content: space-around;">
                <div class="col col-6 col-md-3">
                    <button style="width: 100%;margin:10px" class="btn btn-outline-danger" (click)="showAtivos()">ATIVOS</button>
                </div>
                <div class="col col-6 col-md-3">
                    <button style="width: 100%;margin:10px" class="btn btn-outline-danger" (click)="showInadimplentes()">INADIMPLENTES</button>
                </div>
                <div class="col col-6 col-md-3">
                    <button style="width: 100%;margin:10px" class="btn btn-outline-danger" (click)="showCancelados()">CANCELADOS</button>
                </div>
                <div class="col col-6 col-md-3">
                    <button style="width: 100%;margin:10px" class="btn btn-outline-danger" (click)="showTodos()">MOSTRAR TODOS</button>
                </div>
                <div class="col col-12 col-md-3">
                    <button style="width: 100%;margin:10px"class="btn btn-primary" (click)="openModalNovoPj()" [disabled]="empresa.situacao != 'ATIVO'">
                        CADASTRAR NOVO COLABORADOR 
                    </button>
                </div>
            </div>
            <br>
            <div class="table-responsive">
                <table class="table table-striped" style="text-align: center;">
                    <thead class="thead-dark">
                        <tr>
                            <th></th>
                            <th>Adesão</th>
                            <th>Matricula</th>
                            <th>Colaborador</th>
                            <th>CPF</th>
                            <th>Contrato</th>
                            <th>Celular</th>
                            <th>Situação</th>
                            <th>Cancelar</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let associado of associadosEmpresa;let i = index" >
                        <tr [ngStyle]="{'background-color': associado?.situacao == 'CANCELADO' ? '#c7444a' : null, 'color':associado?.situacao == 'CANCELADO' ?  '#ffffff' : '#000000'}">
                            <td>{{i + 1}}</td>
                            <td>{{ associado?.dataAdesao | date: 'dd/MM/yyyy' }}</td>
                            <td>{{ associado?.matricula }}</td>
                            <td>{{ associado?.nome }}</td>
                            <td>{{ associado?.cpf }}</td>
                            <td>{{ associado?.subContrato }} <span *ngIf="associado?.planoFisicoCheck && !associado?.planoEmpresarialCheck">PLANO FISICO</span><span *ngIf="!associado?.planoFisicoCheck && associado?.planoEmpresarialCheck">PLANO EMPRESARIAL</span></td>
                            <td>{{ associado?.celular }}</td>
                            <td>{{ associado?.situacao }}</td>
                            <td>
                                <button class="btn btn-outline-danger" (click)="cancelarAssociado(associado,i)" [disabled]="associado?.situacao == 'CANCELADO'">
                                    <fa-icon [icon]="['fas', 'user-slash']"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </tab>
        <tab heading="FINANCEIRO" style="padding:10px">
            <div style="height: 2em;"></div>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <td>Vencimento</td>
                            <td>Data Pagamento</td>
                            <td>Valor</td>
                            <td>Status</td>
                            <td>Boleto</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let conta of financeiro">
                            <td>{{conta?.vencimento  | date:'dd/MM/yyyy'}}</td>
                            <td>{{conta?.dataPagamento  | date:'dd/MM/yyyy'}}</td>
                            <td>{{conta?.valorTotal | currency: 'BRL'}}</td>
                            <td style="font-weight:bold;" [ngStyle]="{'color': conta?.status == 'Pago' ? 'green' : 'red'}">{{conta?.status}}</td>
                            <td>
                                <button class="btn btn-primary" (click)="downloadBoleto(conta?.anexos[0]?.url)" [disabled]="!conta?.anexos[0]?.url || conta?.status == 'Pago'">
                                    <fa-icon [icon]="['fas', 'download']"></fa-icon>
                            </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </tab>
    </tabset>
</div>