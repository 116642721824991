<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}" id="rightCarteira">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <div class="titulo">Solicitações Diversas</div>
        <div class="descricao">Utilize o formulário abaixo para realizar suas solicitações.</div>
        <div class="box">
            <div class="miolo">
                <div class="row" style="text-align: center;font-weight: bold;" *ngIf="fim">
                    <div class="col">
                        <h2>Formulário Enviado com Sucesso!</h2>
                        <br>
                        <div style="color:green;font-size: 4em;">
                            <fa-icon size="3x" [icon]="['fas', 'check-circle']"></fa-icon>
                        </div>
                        <br><br>
                        <p>Agradecemos seu contato, em breve entraremos em contato!</p>
                    </div>
                </div>
                <div class="row" *ngIf="!stepperShow" [hidden]="fim">
                    <div class="col col-12 col-md-12">
                        <label>Tipos de Solicitação</label>
                        <select class="form-control" [(ngModel)]="tipo" (change)="limpar()" [ngStyle]="{'border-color': !tipo ? '#dd443e' : '#3cb42c'}">
                            <option value="" selected disabled>Clique Aqui</option>
                            <option value="ACIONAMENTO DE SEGURO" *ngIf="!titular?.hibrido || app.tipoEmpresa == 'VIVA MAX'">ACIONAMENTO DE SEGURO</option>
                            <option value="REEMBOLSO DE DESPESAS COM FUNERAL" *ngIf="!titular?.hibrido || app.tipoEmpresa == 'VIVA MAX'">REEMBOLSO DE DESPESAS COM FUNERAL</option>
                            <option value="OUTROS">OUTROS</option>
                        </select>
                    </div>
                    <div class="col col-12 col-md-6">
                        <label>Nome Completo</label>
                        <input type="text" class="form-control" [(ngModel)]="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': !nome ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-12 col-md-6">
                        <label>Telefone</label>
                        <input type="text" [textMask]="{mask: MASKS.telefone.textMaskFunction}" class="form-control" [(ngModel)]="telefone" [ngStyle]="{'border-color': !telefone ? '#dd443e' : '#3cb42c'}">
                    </div>
                </div>
                <div class="row" *ngIf="!stepperShow" [hidden]="fim">
                    <div class="col col-12 col-md-12">
                        <label>E-mail</label>
                        <input type="text" class="form-control" [(ngModel)]="email" autocomplete="off"
                        [ngStyle]="{'border-color': !email ? '#dd443e' : '#3cb42c'}"
                        (keyup)="app.checkEmail(email)" oninput="this.value = this.value.toUpperCase()">
                        <div class="errorMsg" *ngIf="email && !app.checkEmail(email)">E-mail inválido!</div>
                    </div>
                </div>
                <div class="row" *ngIf="tipo == 'CANCELAMENTO' && !stepperShow" style="text-align:center;margin-top:15px">
                    <div class="col col-12 col-md-12">
                        <button class="btn btn-success btn-lg" (click)="irCancelamento()" [disabled]="!nome || !telefone || !email">AVANÇAR</button>
                    </div>
                </div>
                <div *ngIf="stepperShow">
                        <mat-horizontal-stepper linear #stepper class="full-width" [hidden]="fim"> 
                            <mat-step [stepControl]="1" [editable]="false">
                                <label>Já está de posse do Modelo de Carta de Cancelamento?</label>
                                <select class="form-control" [(ngModel)]="posseCancelamento">
                                    <option value="SIM">SIM</option>
                                    <option value="NÃO">NÃO</option>
                                </select>
                                <div *ngIf="posseCancelamento == 'SIM'" style="text-align: center;">
                                    <br>
                                    <span style="color:brown; font-weight: bold;">Certifique-se que todos os campos estão preenchidos!</span>
                                    <br><br>
                                    <div class="row items-align-center">
                                        <div class="col">
                                            <button class="btn btn-primary" (click)="voltarCancelamento()">VOLTAR</button>
                                        </div>
                                        <div class="col">
                                            <button class="btn btn-success" matStepperNext>AVANÇAR</button>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row items-align-center" style="text-align: center;" *ngIf="posseCancelamento == 'NÃO'">
                                    <div class="col">
                                        <button class="btn btn-primary" (click)="voltarCancelamento()">VOLTAR</button>
                                    </div>
                                    <div class="col">
                                        <button class="btn btn-success" (click)="baixarFormularioCancelamento()">BAIXAR FORMULÁRIO</button>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step [stepControl]="2" [editable]="false">
                                <div class="row">
                                    <div class="col col-md-2 col-12">
                                        <label>Matricula:</label><br>
                                        {{titular?.matricula}}
                                    </div>
                                    <div class="col col-md-5 col-12">
                                        <label>Titular:</label><br>
                                        {{titular?.nome}}
                                    </div>
                                    <div class="col col-md-5 col-12">
                                        <label>Plano:</label><br>
                                        {{titular?.plano}}
                                    </div>
                                </div>
                                <br>
                                <div *ngIf="titular?.dependentes?.length > 0">
                                    <label>Dependentes</label>
                                    <div class="row" *ngFor="let dep of titular?.dependentes">
                                        <div class="col col-md-12 col-12">
                                            {{dep.nome}}
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="titular?.dependentesExtra?.length > 0">
                                    <label>Dependentes Extra</label>
                                    <div class="row" *ngFor="let depExtra of titular?.dependentesExtra">
                                        <div class="col col-md-12 col-12">
                                            {{depExtra.nome}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label>Motivo do Cancelamento:</label>
                                    </div>
                                </div>
                                <div class="row" style="text-align: center;">
                                    <div class="col col-md-12 col-12">
                                        <select class="form-control" [(ngModel)]="motivoCancelamento">
                                            <option value="" selected></option>
                                            <option *ngFor="let mot of motivosCancelamento" [value]="mot.nome">{{mot.nome}}</option>
                                        </select>                
                                    </div>
                                </div>
                                <div class="row" style="text-align: left;">
                                    <div class="col col-md-12 col-12">
                                        <textarea class="form-control" [(ngModel)]="observacaoCancelamento" rows="4"></textarea>
                                        <span style="color:red;font-weight: bold;" [hidden]="observacaoCancelamento.length > 10">{{10 - observacaoCancelamento.length}} caracteres restantes.</span>
                                    </div>
                                </div>
                                <div class="row" style="text-align: center;">
                                    <div class="col">
                                        <button class="btn btn-success" matStepperNext [disabled]="!motivoCancelamento || observacaoCancelamento.length < 10">AVANÇAR</button>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step [stepControl]="3" [editable]="false">
                                <div class="form-check" style="font-size:1.5em">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="checkUm" id="checkUm">
                                    <label class="form-check-label" for="checkUm">
                                        Estou ciente que a partir do protocolo do cancelamento eu e meus beneficiários não teremos mais direito algum sobre quaisquer serviços oferecidos pela Viva Max;
                                    </label>
                                </div>
                                <br>
                                <div class="form-check" style="font-size:1.5em">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="checkDois" id="checkDois">
                                    <label class="form-check-label" for="checkDois">
                                        Estou ciente que a solicitação de cancelamento do plano com pendências financeiras é nulo;
                                    </label>
                                </div>        
                                <br>
                                <div class="row" style="text-align: center;">
                                    <div class="col">
                                        <button class="btn btn-success" matStepperNext [disabled]="!checkUm || !checkDois">AVANÇAR</button>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step [stepControl]="4" [editable]="false">
                                <div class="form-check" style="font-size:1.5em">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="checkTres" id="checkTres">
                                    <label class="form-check-label" for="checkTres">
                                        Estou ciente que a utilização do Convênio de Saúde após o cancelamento configura crime previsto no artigo 171 do Código Penal Brasileiro, sob pena de reclusão;
                                    </label>
                                </div>
                                <br>
                                <div class="form-check" style="font-size:1.5em">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="checkQuatro" id="checkQuatro">
                                    <label class="form-check-label" for="checkQuatro">
                                        Estou ciente que não haverá reembolso de eventuais descontos em folha de pagamento ou lançamento recorrente no cartão de crédito havidos entre a data de protocolo da solicitação de cancelamento e o prazo de 30 (trinta) dias para efetivação do mesmo, se o desconto ou o crédito já houver sido lançado para cobrança.
                                    </label>
                                </div>          
                                <br>
                                <div class="row" style="text-align: center;">
                                    <div class="col">
                                        <button class="btn btn-success" matStepperNext [disabled]="!checkTres || !checkQuatro">AVANÇAR</button>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step [stepControl]="4" [editable]="false">
                                <div class="row" *ngIf="!fim">
                                    <div class="col col-12" style="text-align:center;">
                                        <label>Anexar Formulário Preenchido</label><br>
                                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onChangeAnexo($event)">
                                            <input hidden type="file" #fileInput (change)="onChangeClickAnexo($event)" multiple>
                                        </div>
                                            <div class="files-list" *ngFor="let file of anexos;let i= index">
                                            <p>{{ file }}</p>
                                        </div> 
                                    </div>
                                    <br>
                                    <div class="row" style="color:brown;text-align: justify; font-weight: bold;">
                                        <div class="col">
                                            IMPORTANTE: Para contratos com pagamento através de desconto em folha, informe-se junto ao sindicato, associação ou departamento de recursos humanos a respeito da necessidade do envio da via original. Ficou com dúvida, entre em contato conosco.
                                        </div>
                                    </div>
                                    <br>
                                    <div class="col col-12" style="text-align: center;">
                                        <button class="btn btn-success btn-lg" (click)="solicitarCancelamento()" [disabled]="anexos.length < 1 || enviando">ENVIAR FORMULÁRIO PREENCHIDO</button>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-horizontal-stepper>
                </div>
                <div class="row" *ngIf="tipo == 'ACIONAMENTO DE SEGURO'" [hidden]="fim">
                    <div class="col col-12 col-md-7">
                        <label>Tipo de Seguro</label>
                        <select class="form-control" [(ngModel)]="tipoSeguro">
                            <option value="MORTE NATURAL DO TITULAR">MORTE NATURAL DO TITULAR</option>
                            <option value="MORTE ACIDENTAL DO TITULAR">MORTE ACIDENTAL DO TITULAR</option>
                            <option value="MORTE NATURAL DO CÔNJUGE">MORTE NATURAL DO CÔNJUGE</option>
                            <option value="MORTE ACIDENTAL DO CÔNJUGE">MORTE ACIDENTAL DO CÔNJUGE</option>
                            <option value="CESTA NATALIDADE">CESTA NATALIDADE</option>
                            <option value="DOENÇAS GRAVES">DOENÇAS GRAVES</option>
                        </select>
                    </div>
                    <div class="col col-12 col-md-5" *ngIf="tipoSeguro" style="text-align:center;margin-top:15px">
                        <button class="btn btn-primary btn-lg" (click)="baixarFormularioSeguro()">BAIXAR FORMULÁRIO</button>
                    </div>
                </div>
                <div class="row" *ngIf="tipoSeguro" [hidden]="fim">
                    <div class="col" style="text-align:left;">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="checkCheck" id="checkSeguro">
                            <label class="form-check-label" for="checkSeguro">
                              Já baixei e preenchi o formulário de Acionamento de Seguro.
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="tipo == 'REEMBOLSO DE DESPESAS COM FUNERAL'" [hidden]="fim">
                    <div class="col col-12 col-md-7">
                        <label>Tipo de Reembolso</label>
                        <select class="form-control" [(ngModel)]="tipoReembolso">
                            <option value="ÓBITO DO TITULAR">ÓBITO DO TITULAR</option>
                            <option value="ÓBITO DO CÔNJUGE">ÓBITO DO CÔNJUGE</option>
                            <option value="ÓBITO DE FILHOS">ÓBITO DE FILHOS</option>
                            <option value="ÓBITO DE DEPENDENTES EXTRAS">ÓBITO DE DEPENDENTES EXTRAS</option>
                        </select>
                    </div>
                    <div class="col col-12 col-md-5" *ngIf="tipoReembolso" style="text-align:center;margin-top:15px">
                        <button class="btn btn-primary btn-lg" (click)="baixarFormularioReembolso()">BAIXAR FORMULÁRIO</button>
                    </div>
                </div>
                <div class="row" *ngIf="tipoReembolso" [hidden]="fim">
                    <div class="col" style="text-align:left;">
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="checkCheck" id="checkSeguro">
                            <label class="form-check-label" for="checkSeguro">
                              Já baixei e preenchi o formulário de Reembolso de Despesas com Funeral.
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="tipo == 'OUTROS' && !fim">
                    <div class="row">
                        <div class="col col-12 col-md-12">
                            <label>SAC</label>
                            <select class="form-control" [(ngModel)]="tipoOutros">
                                <option value="ELOGIO">ELOGIO</option>
                                <option value="RECLAMAÇÃO">RECLAMAÇÃO</option>
                                <option value="SUGESTÃO">SUGESTÃO</option>
                                <option value="OUTROS">OUTROS</option>                           
                            </select>                       
                        </div>
                        <div class="col col-12 col-md-12">
                            <label>Descrição</label>
                            <textarea class="form-control" rows="8" [(ngModel)]="texto" oninput="this.value = this.value.toUpperCase()"></textarea>
                        </div>
                        <div class="col col-12 col-md-12" style="text-align:center;padding-top:2em;">
                            <button class="btn btn-primary btn-lg" (click)="enviarEmailSac(nome,email,telefone,tipoOutros,texto)" [disabled]="!nome || !email  || !telefone || !tipo || !texto || enviando">ENVIAR</button>
                        </div>
                    </div>
                </div>
                <br>
               <div class="row" *ngIf="checkCheck && tipoReembolso && !fim">
                    <div class="col col-12" style="text-align:center;">
                        <label>Anexar Formulário Preenchido</label><br>
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onChangeAnexo($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClickAnexo($event)" multiple>
                        </div>
                            <div class="files-list" *ngFor="let file of anexos;let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-lg" (click)="enviarEmailReembolso(nome,email,telefone,tipoReembolso)" [disabled]="!nome || !email || !telefone || !tipoReembolso || anexos.length < 1 || enviando">ENVIAR FORMULÁRIO PREENCHIDO</button>
                    </div>
                </div>
                <div class="row" *ngIf="checkCheck && tipoSeguro && !fim">
                    <div class="col col-12" style="text-align:center;">
                        <label>Anexar Formulário Preenchido</label><br>
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onChangeAnexo($event)">
                            <input hidden type="file" #fileInput (change)="onChangeClickAnexo($event)" multiple>
                        </div>
                            <div class="files-list" *ngFor="let file of anexos;let i= index">
                            <p>{{ file }}</p>
                        </div> 
                    </div>
                    <div class="col col-12" style="text-align: center;">
                        <button class="btn btn-success btn-lg" (click)="enviarEmailSeguro(email,telefone,tipoSeguro)" [disabled]="!nome || !email || !telefone || !tipoSeguro || anexos.length < 1 || enviando">ENVIAR FORMULÁRIO PREENCHIDO</button>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>