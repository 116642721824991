import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from './auth.service';
import { DataBaseService } from './provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  url:any = "";
  mensagemAlerta:any = "";
  loginScreen:boolean = false;
  isDesktop:boolean = false;
  usuario:any = [];
  hibrido:boolean = false;
  telefonePlantao:any = "";
  tipoEmpresa:any = "";
  
  constructor(public router:Router,private deviceService: DeviceDetectorService, public auth:AuthService, public db:DataBaseService){}

  isPlantao(): boolean {
    const now = new Date();
    const day = now.getDay();
    const hour = now.getHours();
    if (day === 6 || day === 0) {
      return true;
    }
    if (day >= 1 && day <= 5) {
      if (hour >= 18 || hour < 8) {
        return true;
      }
    }
    return false;
  }
  
  ngOnInit(){
    this.navEvents();
    this.db.tipoUsuario = localStorage.getItem('tipoUsuarioVM');
    this.isDesktop = this.deviceService.isDesktop();
    if(!localStorage.getItem('idUserVM') && !localStorage.getItem('idServidor')){
        this.auth.logOut();
    }else{
      this.db.getConfigs().subscribe((x:any) => {
        this.telefonePlantao = x[27].telefonePlantao;
        this.db.emails = x[23].emails;
        let idxCancelamento = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["cancelamentos"]');
        if(idxCancelamento >= 0){
          let dadosCancelamento:any = Object.values(this.db.emails[idxCancelamento])[0];
          this.db.emailCancelamento = dadosCancelamento.email;
          this.db.idCancelamento = dadosCancelamento.idResponsavel;
          this.db.responsavelCancelamento = dadosCancelamento.nomeResponsavel;
        }
        let idxSac = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["sac"]');
        if(idxSac >= 0){
          let dadosSac:any = Object.values(this.db.emails[idxSac])[0];
          this.db.emailSac = dadosSac.email;
          this.db.idSac = dadosSac.idResponsavel;
          this.db.responsavelSac = dadosSac.nomeResponsavel;
        }
        let idxReembolso = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["reembolso"]');
        if(idxReembolso >= 0){
          let dadosReembolso:any = Object.values(this.db.emails[idxReembolso])[0];
          this.db.emailReembolso = dadosReembolso.email;
          this.db.idReembolso = dadosReembolso.idResponsavel;
          this.db.responsavelReembolso = dadosReembolso.nomeResponsavel;
        }
        let idxDependentes = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["dependentes"]');
        if(idxDependentes >= 0){
          let dadosDependentes:any = Object.values(this.db.emails[idxDependentes])[0];
          this.db.emailDependentes = dadosDependentes.email;
          this.db.idDependentes = dadosDependentes.idResponsavel;
          this.db.responsavelDependentes = dadosDependentes.nomeResponsavel;
        }
        let idxCarteirinhas = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["carteirinhas"]');
        if(idxCarteirinhas >= 0){
          let dadosCarteirinhas:any = Object.values(this.db.emails[idxCarteirinhas])[0];
          this.db.emailCarteirinhas = dadosCarteirinhas.email;
          this.db.idCarteirinhas = dadosCarteirinhas.idResponsavel;
          this.db.responsavelCarteirinhas = dadosCarteirinhas.nomeResponsavel;
        }
      })
      if(localStorage.getItem('idUserVM')){
        this.db.getAssociadosById(localStorage.getItem('idUserVM')).subscribe((user:any) => {
          if(user && user.situacao != 'CANCELADO'){
            if(user.situacao == 'INADIMPLENTE' || user.situacao == 'INADIMPLENTE II' || user.situacao == 'AFASTADO INADIMPLENTE'){
              this.showAlert('Olá '+user.nome.split(' ')[0]+', identificamos uma pendência financeira em seu cadastro, entre em contato Fone/Whatsapp (45)9151-4374 ou e-mail financeiro@vivamax.com.br e regularize a situação para não perder seus benefícios. Caso o pagamento já tenha sido efetuado, por favor, desconsidere esta mensagem.')
            }
            this.tipoEmpresa = user.empresa;
            this.hibrido = user.hibrido;
            }else{
              this.auth.logOut();
            }
          })
        }
        if(localStorage.getItem('idServidor')){
          this.db.idSeguro = localStorage.getItem('idServidor');
          this.db.getServidorById(localStorage.getItem('idServidor')).subscribe((user:any) => {
            if(user && user.situacao != 'CANCELADO'){
              if(user.situacao == 'INADIMPLENTE' || user.situacao == 'INADIMPLENTE II' || user.situacao == 'AFASTADO INADIMPLENTE'){
                this.showAlert('Olá '+user.nome.split(' ')[0]+', identificamos uma pendência financeira em seu cadastro, entre em contato Fone/Whatsapp (45)9151-4374 ou e-mail financeiro@vivamax.com.br e regularize a situação para não perder seus benefícios. Caso o pagamento já tenha sido efetuado, por favor, desconsidere esta mensagem.')
              }
              if(user.servicos.length > 0){
                this.db.servicosContratados = [];
                for(let x:any = 0; x < user.servicos.length;x++){
                  this.db.servicosContratados.push(user.servicos[x].nome)
                  }
                }
              }else{
                this.auth.logOut();
              }
            })
        }
    }
  }

  private navEvents(){
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
      this.url = e.url;
      this.loginScreen = (e.url == "/login" || e.url == "/empresa") ? true : false;
      if(this.db.tipoUsuario == "Empresa" && this.url != "/empresa"){
        this.router.navigate(['/empresa']);
      }else{
        if(localStorage.getItem('idUserVM')){
          this.db.getAssociadosById(localStorage.getItem('idUserVM')).subscribe((user:any) => {
            this.usuario = user;
          })
        }
        if(localStorage.getItem('idServidor')){
          this.db.idSeguro = localStorage.getItem('idServidor');
          this.db.getServidorById(localStorage.getItem('idServidor')).subscribe((user:any) => {
            this.usuario = user;
            if(user.servicos.length > 0){
              this.db.servicosContratados = [];
              for(let x:any = 0; x < user.servicos.length;x++){
                this.db.servicosContratados.push(user.servicos[x].nome)
                }
              }
          })
        }
      }
    });
  }

  goTo(local){ 
    this.router.navigate(['/'+local]);
  }
  
  whats(){
    window.open('https://wa.me/+5545999210125?text=Ola%20Viva%20Max,%20Preciso%20de%20Ajuda%21','_blank');
  }

  showAlert(mensagem:any){
    let modAlert:any = document.getElementById('alerta');
    modAlert.style.display = "block";
    this.mensagemAlerta = mensagem;
  }
  
  closeAlert(){
    let modAlert:any = document.getElementById('alerta');
    modAlert.style.display = "none";
    this.mensagemAlerta = "";
  }

  telPlantao(){
    window.open('tel:'+this.telefonePlantao,'_blank')
  }

  teleConsulta(){
    let urlConecta:any = "https://conecta.videoconsultas.app/paciente/autogestion";
    window.open(urlConecta,'_blank')
  }

checkEmail(email:any):boolean{
  if (email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(email)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

checkCPF(c:any):boolean{
  if(c){
    let cpf:any = c.replace(/[.\-]/g, '')
      if(cpf && cpf.length >= 11){
        if(!cpf || cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999"){
            return false;
        }
        let soma:number = 0;
        let resto:number = 0;
        for(let i = 1; i <= 9; i++){
          soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
        }
        resto = (soma * 10) % 11;
        if((resto == 10) || (resto == 11)){
          resto = 0;
        }  
        if(resto != parseInt(cpf.substring(9, 10))){
          return false;
        }else{
          soma = 0;
        }
        for(let i = 1; i <= 10; i++){
          soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        }
        resto = (soma * 10) % 11;
        if((resto == 10) || (resto == 11)){
          resto = 0;
        }
        if(resto != parseInt(cpf.substring(10, 11))){
          return false;
        }
      return true;
    }else{
      return false;
    }
  }
}


}