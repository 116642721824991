import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';
import { MASKS } from 'ng-brazil';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-formularios',
  templateUrl: './formularios.component.html',
  styleUrls: ['./formularios.component.scss']
})
export class FormulariosComponent implements OnInit {
  public MASKS = MASKS;
  tipo:any = "";
  tipoSeguro:any = "";
  tipoReembolso:any = "";
  tipoOutros:any = "";
  telefone:any = "";
  nome:any = "";
  email:any = "";
  anexos:any = [];
  texto:any = "";
  anexo:any = "";
  selectedFiles:any = [];
  checkCheck:boolean = false;
  stepperShow:boolean = false;
  enviando:boolean = false;
  posseCancelamento = "";
  titular:any = [];
  formularios:any = [];
  motivosCancelamento:any = [];
  motivoCancelamento:any = "";
  checkUm:boolean = false;
  checkDois:boolean = false;
  checkTres:boolean = false;
  checkQuatro:boolean = false;
  fim:boolean = false;
  dataHoje:any = "";
  observacaoCancelamento:any = "";
  arrayEmails:any = [];

  constructor(public app:AppComponent,public db:DataBaseService) { }

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.db.getAssociadosById(localStorage.getItem('idUserVM')).subscribe((data:any) => {
      this.titular = data;
    })
    this.db.getFormularios().subscribe((data:any) => {
      this.formularios = data;
      this.db.getConfigs().subscribe((x:any) => {
        this.motivosCancelamento = x[13].motivosCancelamento;
        this.db.emails = x[23].emails;
        let idxCancelamento = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["cancelamentos"]');
        if(idxCancelamento >= 0){
          let dadosCancelamento:any = Object.values(this.db.emails[idxCancelamento])[0];
          this.db.emailCancelamento = dadosCancelamento.email;
          this.db.idCancelamento = dadosCancelamento.idResponsavel;
          this.db.responsavelCancelamento = dadosCancelamento.nomeResponsavel;
        }
        let idxSac = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["sac"]');
        if(idxSac >= 0){
          let dadosSac:any = Object.values(this.db.emails[idxSac])[0];
          this.db.emailSac = dadosSac.email;
          this.db.idSac = dadosSac.idResponsavel;
          this.db.responsavelSac = dadosSac.nomeResponsavel;
        }
        let idxReembolso = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["reembolso"]');
        if(idxReembolso >= 0){
          let dadosReembolso:any = Object.values(this.db.emails[idxReembolso])[0];
          this.db.emailReembolso = dadosReembolso.email;
          this.db.idReembolso = dadosReembolso.idResponsavel;
          this.db.responsavelReembolso = dadosReembolso.nomeResponsavel;
        }
        let idxDependentes = this.db.emails.map((e:any) => {
          return JSON.stringify(Object.keys(e));
        }).indexOf('["dependentes"]');
        if(idxDependentes >= 0){
          let dadosDependentes:any = Object.values(this.db.emails[idxDependentes])[0];
          this.db.emailDependentes = dadosDependentes.email;
          this.db.idDependentes = dadosDependentes.idResponsavel;
          this.db.responsavelDependentes = dadosDependentes.nomeResponsavel;
        }
      })
    })
    this.limpar();
  }

  limpar(){
    this.tipoSeguro = "";
    this.tipoReembolso = "";
    this.tipoOutros = "";
    this.checkCheck = false;
    this.fim = false;
  }

  onChangeClickAnexo(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    this.selectedFiles = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG ou .PNG")
        this.selectedFiles = "";
      }} 
      this.anexos = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.anexos.push(this.selectedFiles[i].name);
      }
  }
  
  onChangeAnexo(event){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    this.selectedFiles = <FileList>event;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type)){
        alert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG ou .PNG")
        this.selectedFiles = "";
      }} 
      this.anexos = [];
      for(let i=0; i<this.selectedFiles.length; i++){
        this.anexos.push(this.selectedFiles[i].name);
      }
}

  irCancelamento(){
    this.stepperShow = true;
  }

  voltarCancelamento(){
    this.stepperShow = false;
  }

  solicitarCancelamento(){
      this.enviando = true;
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let dados = {
        solicitouCancelamento : true,
        motivoSolicitacaoCancelamento : this.motivoCancelamento,
        observacaoCancelamento: this.observacaoCancelamento,
        dataSolicitacaoCancelamento : this.dataHoje,
        horaSolicitacaoCancelamento : hora,
        usuarioSolicitacoCancelamento : this.titular.nome
      }
      this.db.patchAssociado(this.titular._id,dados).subscribe(ress => {
        console.log(ress)
        let dados = {
          "data" : this.dataHoje, 
          "hora" : hora, 
          "motivo" : "SOLICITAÇÃO DE CANCELAMENTO", 
          "msg" : this.motivoCancelamento+" - "+this.observacaoCancelamento, 
          "usuario" : this.nome+" (AREA DO CLIENTE)"
        }
        this.db.newHistorico(this.titular._id,dados).subscribe(res => {
        console.log(res);
        let body = {
          "menu" : "AREA DO CLIENTE", 
          "descricao" : "Solicitação de Cancelamento do Associado "+this.titular.nome+" - Matricula:"+this.titular.matricula+", motivo: "+this.motivoCancelamento+", observação: "+this.observacaoCancelamento, 
          "data" : this.dataHoje, 
          "hora" : hora, 
          "usuario" : this.nome
        }
        this.db.postLogs(body).subscribe(result => {
          console.log(result);
          this.enviando = false;
          const imagedata = new FormData();
          for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
          let protocolo = Math.floor(Math.random() * 1000000000);          
          this.db.sendEmailCancelamento(this.email,this.db.emailCancelamento,this.titular.matricula, protocolo, this.nome, this.titular.nome,this.telefone, this.motivoCancelamento+" - "+this.observacaoCancelamento, imagedata).subscribe((res:any) => {
             console.log(res);
            }, err => {
              console.log(err)
            })
              let randomInd = Math.random().toString(36).slice(-8);
              let indice = this.arrayEmails.map((itemT:any) => {
                return itemT.email.toLowerCase();
              }).indexOf('financeiro2@vivamax.com.br')
              if(indice >= 0){
              let obj = {
                "id": randomInd,
                "data" : this.dataHoje,
                "hora" : hora,
                "titulo" : "CANCELAMENTO AREA CLIENTE",
                "descricao" : "Associado: "+ this.titular.nome + " - Matricula: " + Number(this.titular.matricula),
                "idResponsavel" : this.arrayEmails[indice]._id,
                "responsavel" : this.arrayEmails[indice].nome,
                "resolvido":false,
                "dataResolucao" : "",
                "horaResolucao" : "",
                "prioridade" : "ALTA",
                "origem":"Sistema",
                "idUserSolicitacao":"",
                "nomeSolicitante":"ÁREA DO CLIENTE"
              }
              this.db.postTarefa(obj).subscribe(resT => {
                console.log(resT);
                this.fim = true;
              }, errT => console.log(errT))
            }
        },err => {
            console.log(err);
            alert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
          })
        },err => {
          console.log(err);
          alert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
        })
      },err => {
        console.log(err);
        alert("Falha ao Enviar, por favor recarregue a página e solicite novamente")
      })
  }

  enviarEmailSac(nome,email,telefone,tipoSac,texto){
    this.enviando = true;
    this.db.sendEmailSac(email,this.db.emailSac,this.titular.matricula,nome,this.titular.nome,telefone,texto,tipoSac).subscribe((res:any) => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let indice = this.arrayEmails.map((itemT:any) => {
        return itemT.email.toLowerCase();
      }).indexOf('adminstrativo@vivamax.com.br')
      if(indice >= 0){
      let randomInd = Math.random().toString(36).slice(-8);
      let obj = {
        "id": randomInd,
        "data" : this.dataHoje,
        "hora" : hora,
        "titulo" : "SAC AREA CLIENTE",
        "descricao" : "Associado: "+ this.titular.nome + " - Matricula: " + Number(this.titular.matricula),
            "idResponsavel" : this.arrayEmails[indice]._id,
            "responsavel" : this.arrayEmails[indice].nome,
        "resolvido":false,
        "dataResolucao" : "",
        "horaResolucao" : "",
        "prioridade" : "ALTA",
        "origem":"Sistema",
        "idUserSolicitacao":"",
        "nomeSolicitante":"ÁREA DO CLIENTE"
      }
      this.db.postTarefa(obj).subscribe(resT => {
        console.log(resT);
        let dados = {
          "data" : this.dataHoje, 
          "hora" : hora, 
          "motivo" : tipoSac, 
          "msg" : texto, 
          "usuario" : this.nome+" (AREA DO CLIENTE)"
        }
        this.db.newHistorico(this.titular._id,dados).subscribe(resH => {
          console.log(resH);
        this.fim = true;
        this.enviando = false;
      }, errH=> console.log(errH))
    }, errT => console.log(errT))
  }
     }, err => {
       console.log(err)
       this.fim = true;  
    })
  }

  enviarEmailReembolso(nome,email,telefone,tipoReembolso){
    this.enviando = true;
    let valorReembolso = 15;
    const imagedata = new FormData();
    for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
    this.db.sendEmailReembolso(email,this.db.emailReembolso,this.titular.matricula,this.titular.nome,nome,telefone,tipoReembolso,valorReembolso,imagedata).subscribe((res:any) => {
      console.log(res);
     }, err => {
       console.log(err);
    })
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let indice = this.arrayEmails.map((itemT:any) => {
        return itemT.email.toLowerCase();
      }).indexOf('suportevida@iguacuseguros.com.br')
      if(indice >= 0){
      let randomInd = Math.random().toString(36).slice(-8);
      let obj = {
        "id": randomInd,
        "data" : this.dataHoje,
        "hora" : hora,
        "titulo" : "REEMBOLSO AREA CLIENTE",
        "descricao" : "Associado: "+ this.titular.nome + " - Matricula: " + Number(this.titular.matricula),
        "idResponsavel" : this.arrayEmails[indice]._id,
        "responsavel" : this.arrayEmails[indice].nome,
        "resolvido":false,
        "dataResolucao" : "",
        "horaResolucao" : "",
        "prioridade" : "ALTA",
        "origem":"Sistema",
        "idUserSolicitacao":"",
        "nomeSolicitante":"ÁREA DO CLIENTE"
      }
      this.db.postTarefa(obj).subscribe(resT => {
        console.log(resT);
        let dados = {
          "data" : this.dataHoje, 
          "hora" : hora, 
          "motivo" : "SOLICITAÇÃO DE REEMBOLSO", 
          "msg" : tipoReembolso+" - "+valorReembolso, 
          "usuario" : this.nome+" (AREA DO CLIENTE)"
        }
        this.db.newHistorico(this.titular._id,dados).subscribe(resH => {
          console.log(resH);
        this.fim = true;
        this.enviando = false;
      }, errH=> console.log(errH))
    }, errT => console.log(errT))
  }
  }

  enviarEmailSeguro(email,telefone,tipoSeguro){
    this.enviando = true;
    const imagedata = new FormData();
    for(let i=0; i<this.selectedFiles.length; i++){imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name)};
    this.db.sendEmailSeguro(email,this.db.emailSeguro,this.titular.matricula,this.titular.nome,telefone,tipoSeguro,imagedata).subscribe((res:any) => {
      console.log(res);
     }, err => {
       console.log(err);
    })
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let indice = this.arrayEmails.map((itemT:any) => {
      return itemT.email.toLowerCase();
    }).indexOf('suportevida@iguacuseguros.com.br')
    if(indice >= 0){
    let randomInd = Math.random().toString(36).slice(-8);
    let obj = {
      "id": randomInd,
      "data" : this.dataHoje,
      "hora" : hora,
      "titulo" : "SEGURO AREA CLIENTE",
      "descricao" : "Associado: "+ this.titular.nome + " - Matricula: " + Number(this.titular.matricula),
      "idResponsavel" : this.arrayEmails[indice]._id,
      "responsavel" : this.arrayEmails[indice].nome,
      "resolvido":false,
      "dataResolucao" : "",
      "horaResolucao" : "",
      "prioridade" : "ALTA",
      "origem":"Sistema",
      "idUserSolicitacao":"",
      "nomeSolicitante":"ÁREA DO CLIENTE"
    }
    this.db.postTarefa(obj).subscribe(resT => {
      console.log(resT);
      let dados = {
        "data" : this.dataHoje, 
        "hora" : hora, 
        "motivo" : "SOLICITAÇÃO DE SEGURO", 
        "msg" : tipoSeguro, 
        "usuario" : this.nome+" (AREA DO CLIENTE)"
      }
      this.db.newHistorico(this.titular._id,dados).subscribe(resH => {
        console.log(resH);
      this.fim = true;
      this.enviando = false;
    }, errH=> console.log(errH))
  }, errT => console.log(errT))
}
}

  baixarFormularioSeguro(){
    let idx = this.formularios.map((f) => {
      return f.nome;
    }).indexOf("seguro");
    let form = this.formularios[idx].url;
    FileSaver.saveAs(form, "formulario-seguro-vivamax");
  }

  baixarFormularioReembolso(){
    let idx = this.formularios.map((f) => {
      return f.nome;
    }).indexOf("reembolso");
    let form = this.formularios[idx].url;
    FileSaver.saveAs(form, "formulario-reembolso-vivamax");
  }

  baixarFormularioCancelamento(){
    let idx = this.formularios.map((f) => {
      return f.nome;
    }).indexOf("cancelamento");
    let form = this.formularios[idx].url;
    FileSaver.saveAs(form, "formulario-cancelamento-vivamax");
  }
  
  voltarInicio(){
    this.app.router.navigate(['/inicio']);
  }

}