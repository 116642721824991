<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}" id="mainRight">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <div class="infoAzul" *ngIf="usuario?.formaPagamento == 'DEBITO RECORRENTE'">
        <label>Formas de pagamento</label>
        <div style="height:3em"></div>
        <div class="innerCard">
            <div class="row">
                <div class="col">
                    <p><small>**** **** ****</small> {{ usuario?.numeroCartao?.slice(14,19) }}</p>
                    <p>{{ usuario?.validadeCartao }}</p>
                    <p>Débito recorrente</p>
                </div>
                <div class="col col-7" style="text-align:right">
                    <img src="../../assets/img/cartao-cielo.png" *ngIf="usuario?.cartaoSelecionado == 'CIELO'">
                    <img src="../../assets/img/cartao-master.png" *ngIf="usuario?.cartaoSelecionado == 'MASTERCARD'">
                    <img src="../../assets/img/cartao-elo.png" *ngIf="usuario?.cartaoSelecionado == 'ELO'">
                    <img src="../../assets/img/cartao-visa.png" *ngIf="usuario?.cartaoSelecionado == 'VISA'">
                    <img src="../../assets/img/cartao-american.png" *ngIf="usuario?.cartaoSelecionado == 'AMERICAN EXPRESS'">
                    <img src="../../assets/img/cartao-diners.png" *ngIf="usuario?.cartaoSelecionado == 'DINERS CLUB'">
                    <img src="../../assets/img/cartao-hipercard.png" *ngIf="usuario?.cartaoSelecionado == 'HIPERCARD'">
                    <br>
                    <p style="text-align:right;margin-top:2px">Mensalidade: {{ usuario?.totalMensalidade | currency: 'BRL'}}</p>
                </div>
            </div>
            <div class="row" *ngIf="usuario?.formaPagamento != 'DEBITO RECORRENTE'">
                <div class="col">
                    <p>{{ usuario?.formaPagamento }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="table-responsive" *ngIf="usuario.formaPagamento == 'CARNÊ' || usuario.formaPagamento == 'BOLETO MENSAL'">
        <table class="table table-striped">
            <thead>
                <tr>
                    <td>Vencimento</td>
                    <td>Data Pagamento</td>
                    <td>Valor</td>
                    <td>Status</td>
                    <td>Boleto</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let conta of financeiro">
                    <td>{{conta?.vencimento  | date:'dd/MM/yyyy'}}</td>
                    <td>{{conta?.dataPagamento  | date:'dd/MM/yyyy'}}</td>
                    <td>{{conta?.valorTotal | currency: 'BRL'}}</td>
                    <td style="font-weight:bold;" [ngStyle]="{'color': conta?.status == 'Pago' ? 'green' : 'red'}">{{conta?.status}}</td>
                    <td>
                        <button class="btn btn-primary" (click)="downloadBoleto(conta?.anexos[0]?.url)" [disabled]="!conta?.anexos[0]?.url || conta?.status == 'Pago'">
                            <fa-icon [icon]="['fas', 'download']"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
</div>