import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { DataBaseService } from '../provider.service';

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})
export class HistoricoComponent implements OnInit {
  agendamentos:any = [];

  constructor(public app:AppComponent,public db:DataBaseService) { }

  ngOnInit(){
    if(this.db.idServidor){
      let idServidor = localStorage.getItem('idServidor');
      this.db.getServidorById(idServidor).subscribe((data:any) => {
        this.agendamentos = data.agendamentos;
      })
    }else{
      let idUser = localStorage.getItem('idUserVM');
      this.db.getAssociadosById(idUser).subscribe((data:any) => {
        this.agendamentos = data.agendamentos;
      })
    }
  }

    voltarInicio(){
      if(this.db.idServidor){
        this.app.router.navigate(['/servidor']);
      }else{
        this.app.router.navigate(['/inicio']);
      }
    }

}