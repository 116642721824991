<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}" id="rightCarteira">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <div class="titulo">Histórico do Cliente</div>
        <div class="descricao">Visualize abaixo o seu histórico de consultas!</div>
        <div style="height:2em"></div>
        <div class="row history" *ngFor="let consulta of agendamentos">
            <div class="col col-12 col-md-2" [hidden]="!app.isDesktop">
                <label>Data</label>
                <p>{{ consulta.agendadoParaMedico | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="col col-6 col-md-2" [hidden]="app.isDesktop">
                <label>Data</label>
                <p>{{ consulta.agendadoParaMedico | date: 'dd/MM/yyyy' }}</p>
            </div>
            <div class="col col-6 col-md-3" [hidden]="app.isDesktop">
                <label>Municipio</label>
                <p>{{ consulta.cidadeMedico }}</p>
            </div>
            <div class="col col-12 col-md-3">
                <label>Beneficiário</label>
                <p>{{ consulta.pacienteMedico }}</p>
            </div>
            <div class="col col-12 col-md-3">
                <label>Local</label>
                <p>{{ consulta.laboratorioMedico }}</p>
            </div>
            <div class="col col-12 col-md-3" [hidden]="!app.isDesktop">
                <label>Municipio</label>
                <p>{{ consulta.cidadeMedico }}</p>
            </div>
            <div class="col col-1" [hidden]="!app.isDesktop">
                <br>
                <fa-icon [icon]="['fas', 'history']"></fa-icon>
            </div>
        </div>
    </div>
</div>