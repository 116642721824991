<div class="modal-screen" id="alerta">
    <div [ngClass]="!isDesktop? 'inner-mob' : 'inner'">
        <div class="topo-modal">
            Aviso
        </div>
        <div class="content-modal">
            <p class="p-2" style="font-weight:500">
                {{mensagemAlerta}}
            </p>
            <div class="row align-items-center">
                <div class="col col-12 col-md-12">
                    <button class="btn btn-outline-primary btn-block w-50" (click)="closeAlert()" style="margin:0 auto">OK</button>
                </div>
            </div>
        </div>
    </div>
  </div>
<div class="mainMenu" *ngIf="!loginScreen" [hidden]="!isDesktop && url != '/inicio'" [ngStyle]="{'width': isDesktop ? '20em' : '100%'}" id="menuAssociado">
    <div class="topoMenu">
        <img src="../../assets/img/logo.png">           
    </div>
    <div class="containerMenu" *ngIf="db?.idUsuario != ''">
        <p class="titulo">
            Área do cliente
        </p>
        <p class="descricao">
            Seja bem vindo(a) <span style="font-weight:700">{{db?.nomeUsuario}}</span>! Navegue pelos menus abaixo para encontrar o que deseja.
        </p>
        <ul class="menu">
            <li (click)="goTo('/contrato')" [ngClass]="{'active': url == '/contrato'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'file-contract']"></fa-icon>
                    </div>
                    <div class="label">
                        Dados do Contrato
                    </div>
                </button>
            </li>
            <li (click)="goTo('/credenciados')" [ngClass]="{'active': url == '/credenciados'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'star']"></fa-icon>
                    </div>
                    <div class="label">
                        Rede Credenciada
                    </div>
                </button>
            </li>
            <li (click)="teleConsulta()" *ngIf="hibrido || tipoEmpresa == 'CONECTA'">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'stethoscope']"></fa-icon>
                    </div>
                    <div class="label">
                        Telemedicina
                    </div>
                </button>
            </li>
            <li (click)="goTo('/telemedicina')" *ngIf="!hibrido && tipoEmpresa != 'CONECTA'">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'stethoscope']"></fa-icon>
                    </div>
                    <div class="label">
                        Telemedicina
                    </div>
                </button>
            </li>
            <li (click)="goTo('/carteirinhas')" [ngClass]="{'active': url == '/carteirinhas'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'id-card']"></fa-icon>
                    </div>
                    <div class="label">
                        Carteirinha Digital
                    </div>
                </button>
            </li>
            <li (click)="goTo('/financeiro')" [ngClass]="{'active': url == '/financeiro'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'file-invoice-dollar']"></fa-icon>
                    </div>
                    <div class="label">
                        Financeiro
                    </div>
                </button>
            </li>   
            <li (click)="goTo('/historico')" [ngClass]="{'active': url == '/historico'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'clipboard-list']"></fa-icon>
                    </div>
                    <div class="label">
                        Histórico do Cliente
                    </div>
                </button>
            </li>        
            <li (click)="goTo('/sorteio')" [ngClass]="{'active': url == '/sorteio'}" [hidden]="app?.tipoEmpresa != 'VIVA MAX' || !app?.hibrido">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'award']"></fa-icon>
                    </div>
                    <div class="label">
                        Sorteado do Mês
                    </div>
                </button>
            </li>
            <li (click)="goTo('/formularios')" [ngClass]="{'active': url == '/formularios'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'outdent']"></fa-icon>
                    </div>
                    <div class="label">
                        Solicitações Diversas
                    </div>
                </button>
            </li>
            <li (click)="goTo('/agenda')" [ngClass]="{'active': url == '/agenda'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
                    </div>
                    <div class="label">
                        Agenda
                    </div>
                </button>
            </li>
            <li (click)="telPlantao()" *ngIf="isPlantao()">
                <button class="ghost-button" style="background-color:brown !important; padding: 10px !important;border-radius: 10px !important; font-size:16px !important">
                    <div class="label" style="color:#fff !important; margin:0 auto !important">
                        <ion-icon name="call-outline"></ion-icon>
                        Plantão Assistência Funeral<br>{{telefonePlantao}}
                    </div>
                </button>
            </li>
        </ul>
        <div class="bottom">
            <button class="ghost-button">
                <div class="esqueceu">
                    <p>Precisa de ajuda? <span class="link" (click)="whats()" >Clique aqui.</span></p>
                </div>
            </button>
            <button class="btn btn-primary" (click)="auth.logOut()">Sair</button>
        </div>  
    </div>
    <div class="containerMenu" *ngIf="db?.idServidor != ''">
        <p class="titulo">
            Área do Servidor
        </p>
        <p class="descricao">
            Seja bem vindo(a) <span style="font-weight:700">{{db?.nomeUsuario}}</span>! Navegue pelos menus abaixo para encontrar o que deseja.
        </p>
        <ul class="menu">
            <li (click)="goTo('/contrato-servidor')" [ngClass]="{'active': url == '/contrato-servidor'}" *ngIf="db?.tipoUsuario == 'Titular'">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'file-contract']"></fa-icon>
                    </div>
                    <div class="label">
                        Dados do Contrato
                    </div>
                </button>
            </li>
            <li (click)="goTo('/credenciados')" [ngClass]="{'active': url == '/credenciados'}" *ngIf="db?.servicosContratados.includes('CONVÊNIO DE SAÚDE')">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'star']"></fa-icon>
                    </div>
                    <div class="label">
                        Rede Credenciada
                    </div>
                </button>
            </li>
            <li (click)="teleConsulta()" *ngIf="db?.servicosContratados.includes('TELEMEDICINA')">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'stethoscope']"></fa-icon>
                    </div>
                    <div class="label">
                        Telemedicina
                    </div>
                </button>
            </li>
            <li (click)="goTo('/telemedicina')" *ngIf="!db?.servicosContratados.includes('TELEMEDICINA')">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'stethoscope']"></fa-icon>
                    </div>
                    <div class="label">
                        Telemedicina
                    </div>
                </button>
            </li>
            <li (click)="goTo('/carteirinhas')" [ngClass]="{'active': url == '/carteirinhas'}" *ngIf="db?.servicosContratados.includes('CONVÊNIO DE SAÚDE')">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'id-card']"></fa-icon>
                    </div>
                    <div class="label">
                        Carteirinha Digital
                    </div>
                </button>
            </li>
            <li (click)="goTo('/financeiro')" [ngClass]="{'active': url == '/financeiro'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'file-invoice-dollar']"></fa-icon>
                    </div>
                    <div class="label">
                        Financeiro
                    </div>
                </button>
            </li>   
            <li (click)="goTo('/historico')" [ngClass]="{'active': url == '/historico'}">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'clipboard-list']"></fa-icon>
                    </div>
                    <div class="label">
                        Histórico do Cliente
                    </div>
                </button>
            </li>        
            <li (click)="goTo('/formularios')" [ngClass]="{'active': url == '/formularios'}" *ngIf="db?.tipoUsuario == 'Titular'">
                <button class="ghost-button">
                    <div class="icon-menu">
                        <fa-icon [icon]="['fas', 'outdent']"></fa-icon>
                    </div>
                    <div class="label">
                        Solicitações Diversas
                    </div>
                </button>
            </li>
            <li (click)="telPlantao()" *ngIf="isPlantao()">
                <button class="ghost-button" style="background-color:brown !important; padding: 10px !important;border-radius: 10px !important; font-size:16px !important">
                    <div class="label" style="color:#fff !important; margin:0 auto !important">
                        <ion-icon name="call-outline"></ion-icon>
                        Plantão Assistência Funeral<br>{{telefonePlantao}}
                    </div>
                </button>
            </li>
        </ul>
        <div class="bottom">
            <button class="ghost-button">
                <div class="esqueceu">
                    <p>Precisa de ajuda? <span class="link" (click)="whats()" >Clique aqui.</span></p>
                </div>
            </button>
            <button class="btn btn-primary" (click)="auth.logOut()">Sair</button>
        </div>  
    </div>
</div>
<ng-http-loader></ng-http-loader>
<router-outlet></router-outlet>