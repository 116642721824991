<!-- MODAL PRINT PROPOSTA -->
<div id="divToPrint" style="display:none">
    <div class="row">
        <div class="col" style="text-align: left;">
            <img src="../../assets/img/logo.png" style="width: 250px;">
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-8" *ngIf="usuario?.subContrato != ''">
            <label>Sub Contrato:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.subContrato}}
            </div>
        </div>
        <div class="col col-8" *ngIf="usuario?.planoFisicoCheck">
            <label>Plano Fisico:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.plano}}
            </div>
        </div>
        <div class="col col-1">
            &nbsp;
        </div>
        <div class="col col-3">
            <label>Matricula:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.matricula}}
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-5">
            <label>Nome Completo:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.nome}}
            </div>
        </div>
        <div class="col col-2">
            <label>Estado Civil:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.estadoCivil}}
            </div>
        </div>
        <div class="col col-2">
            <label>CPF:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.cpf}}
            </div>
        </div>
        <div class="col col-3">
            <label>Data Adesão:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.dataAdesao | date:'dd/MM/yyyy'}}
            </div>
        </div>
    </div>
    <br>
    <div class="row align-items-center">
        <div class="col col-2">
            <label>Nascimento:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.nascimento | date:'dd/MM/yyyy'}}
            </div>
        </div>
        <div class="col col-4">
            <label>Telefone:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.telefone}}
            </div>
        </div>
        <div class="col col-4">
            <label>Celular:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.celular}}
            </div>
        </div>
        <div class="col col-2">
            <br>
            <div class="form-check" *ngIf="!usuario?.whatsapp">
                <input class="form-check-input" type="checkbox" id="whatsEditarPrint">
                <label class="form-check-label" for="whatsEditarPrint">&nbsp;&nbsp;Whatsapp</label>
            </div>
            <div class="form-check" *ngIf="usuario?.whatsapp">
                <input class="form-check-input" type="checkbox" checked id="whatsEditarPrint">
                <label class="form-check-label" for="whatsEditarPrint">&nbsp;&nbsp;Whatsapp</label>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-2">
            <label>Sexo:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.sexo}}
            </div>
        </div>
        <div class="col col-2">
            <label>CEP:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.cep}}
            </div>
        </div>
        <div class="col col-3">
            <label>Rua:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.rua}}
            </div>
        </div>
        <div class="col col-2">
            <label>Número:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.numero}}
            </div>
        </div>
        <div class="col col-3">
            <label>Bairro:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.bairro}}
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-2">
            <label>Complemento:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.complemento}}
            </div>
        </div>
        <div class="col col-3">
            <label>Município:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.municipio}}
            </div>
        </div>
        <div class="col col-1">
            <label>UF:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.estado}}
            </div>
        </div>
        <div class="col col-3">
            <label>Local Trabalho:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.localTrabalho}}
            </div>
        </div>
        <div class="col col-3">
            <label>Função:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.funcao}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label>E-mail:</label>
            <div class="inputFake">
                &nbsp;{{usuario?.email}}
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
            <label style="line-height: 1.5em;">DADOS CADASTRAIS DOS DEPENDENTES</label>
        </div>
    </div>
    <div *ngIf="usuario?.dependentes?.length == 0">
        NÃO POSSUI DEPENDENTES CADASTRADOS
    </div>
    <div *ngIf="usuario?.dependentes?.length > 0">
        <form [formGroup]="dependentesEditar">
            <div class="row" formArrayName="dependenteEditar">
                <div class="col col-12" *ngFor="let dependenteEditar of dependentesEditarFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row align-items-center">
                        <div class="form-group col-2">
                            <label>Tipo:</label>
                            <div class="inputFake">
                                &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].tipo}}
                            </div>
                        </div>
                        <div class="form-group col-3">
                            <label>Nome:</label>
                            <div class="inputFake">
                                &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].nome}}
                            </div>
                        </div>
                        <div class="form-group col-2">
                            <label>Data Nascimento:</label>
                            <div class="inputFake">
                                &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].nascimento}}
                            </div>
                        </div>
                        <div class="form-group col-2">
                            <label>CPF:</label>
                            <div class="inputFake">
                                &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].cpf}}
                            </div>
                        </div> 
                        <div class="form-group col-3">
                            <label>ASF:</label>
                            <div style="border:1px solid #6b6b6b; width: 100%; line-height:1.5em;padding:5px;border-radius:3px; font-size: 0.8em;">
                                &nbsp;{{dependentesEditar.controls.dependenteEditar.value[i].asf}}
                            </div>
                        </div> 
                    </div>
                </div>
            </div>   
        </form>
    </div>
    <br>
    <strong>DECLARAÇÃO DE SAÚDE</strong>
    <br>
    O proponente ou algum dos dependentes possui ou já possuiu alguma doença, tais como: diabetes, hipertensão arterial, hepatite, AIDS, infarto, doenças cardíacas, tumor maligno (cÂncer), derrane, doenças de chagas, leucemia, enfisema, cirrose, doenças renais, doenças neurológicas, doenças digestivas, doenças pulmonares ou qualquer outroa doença?<br>
    <br>
    <div>
        <label>Proponente Possui Problemas Pré Existentes?</label>
        <div class="row">
            <div class="col col-2">
                <div class="form-check" *ngIf="usuario?.doenca == 'SIM'">
                    <input class="form-check-input" type="checkbox" id="doencaNaoEditarPrint" checked>
                    <label class="form-check-label" for="doencaNaoEditar" style="margin-left:10px">SIM</label>
                </div>
                <div class="form-check" *ngIf="usuario?.doenca != 'SIM'">
                    <input class="form-check-input" type="checkbox" id="doencaSimEditarPrint">
                    <label class="form-check-label" for="doencaSimEditar" style="margin-left:10px">SIM</label>
                </div>
            </div>
            <div class="col col-2">
                <div class="form-check" *ngIf="usuario?.doenca == 'NÃO'">
                    <input class="form-check-input" type="checkbox" id="doencaNaoEditarPrint" checked>
                    <label class="form-check-label" for="doencaNaoEditar" style="margin-left:10px">NÃO</label>
                </div>
                <div class="form-check" *ngIf="usuario?.doenca != 'NÃO'">
                    <input class="form-check-input" type="checkbox" id="doencaNaoEditarPrint">
                    <label class="form-check-label" for="doencaNaoEditar" style="margin-left:10px">NÃO</label>
                </div>
            </div>
            <div class="col col-8" *ngIf="usuario?.doenca == 'SIM'">
                <label>Especificar:</label>
                <div class="inputFake">
                    &nbsp;{{usuario?.especificarDoenca}}
                </div>
            </div>
        </div>     
    </div>
    <p style="page-break-after: always;">&nbsp;</p>
    <p style="page-break-before: always;">&nbsp;</p>
    <div class="row">
        <div class="col" style="text-align: left;">
            <img src="../../assets/img/logo.png" style="width: 250px">
        </div>
    </div>
    <br>
    <strong>ADESÃO E MANUTENÇÃO</strong><br>
    <br>
    <div class="row">
        <div class="col">
            Taxa de Adesão {{ usuario?.taxaAdesao | currency:'BRL' }}
        </div>
        <div class="col">
            Mensalidade Total {{ usuario?.totalMensalidade | currency:'BRL' }}
        </div>
    </div>
    <div style="font-size:12px; text-align: justify;">
        <br>
        Forma de pagamento da mensalidade de acordo com Autorização de Dados de Pagamento vinculado ao contratao.<br>
        <br>
        Estou ciente e autorizo desde já a atualizaçao monetária para correção do valor da mensalidade todo mês de janeiro, de acordo com o Índice de Preços ao Consumidor Amplo - IPCA ou outro que venha a substituí-lo.<br>
        <br>
        <strong>INFORMAÇÕES COMPLEMENTARES</strong><br>
        <br>
        O contrato tem início de vigência na data do desconto em folha de pagamento, pagamento da taxa de adesão ou primeira mensalidade sendo esta data utilizada para contagem dos prazos de carência.<br>
        O limite de idade para adesão ao contrato é de 69 (sessenta e nove) anos para plano físico e 64 (sessenta e quatro) anos para planos especiais de servidores públicos municipais.<br>
        Somente os contratos firmados junto a entidades de classes (sindicatos e associações) e com desconto em folha de pagamento possui cobertura de indenização à família por morte natural do titular. O cônjuge do titular, filhos e dependentes extras não possuem cobertura de indenização por morte natural ou acidental.<br>
        Os contratos não vinculados a entidades de classes, em hipótese alguma terão cobertura de indenização por morte natural do titular, cônjuge, filhos e dependentes extras, tendo direito tão somente a indenização por morte natural ou acidental do titular.<br>
        Cobertura de Cesta Natalidade extensiva somente ao cônjuge do titular devidamente cadastrado.<br>
        Cobertura de assistência funeral extensiva automaticamente para o cônjuge e filhos dependentes com até 18 (dezoito) anos, de acordo com a regra do Imposto de Renda.<br>
        Pai, mãe, sogro, sogra e filhos maiores de 18 (dezoito) anos que não se encaixarem na regra de Imposto de Renda terão cobertura de assistência funeral desde que preenchidos todos os dados (nome completo, data de nascimento e CPF) e se enquadrarem nos critérios de aceitação da contratante, sendo idade limite de 69 (sessenta e nove) anos na data de inclusão, pagamento de mensalidade adicional e inexistência de doenças pré-existentes.<br>
        Cobertura de Morte Natural, Morte Acidental, Assistência Funeral e Cartão Alimentação para titular possuem carência de 90 (noventa) dias a partir da data do início da vigência do contrato.<br>
        Cobertura de Assistência Funeral para cônjuge e filhos possuem carência de 90 (noventa) dias a partir da data do início da vigência do contrato, exceto suicídio, o qual haverá carência de 2 (dois) anos.<br>
        Quando contratada a cobertura de assistência funeral para dependentes extras, sendo filhos acima de 18 (dezoito) anos, pais ou sogros, haverá carência de 180 (cento e oitenta) dias à contar da data de início de vigência do contrato, exceto suicídio, o qual haverá carência de 2 (dois) anos.<br>
        Cobertura de Cesta Natalidade possui carência de 1 (um) ano a partir do início de vigência do contrato, com prazo de prescrição de 3 (três) meses após o nascimento.<br>
        Sorteio mensal vinculado à Loteria Federal, realizado todo último sábado do mês. O associado poderá solicitar a qualquer tempo o número da sorte a que se vincula o contrato.<br>
        Possui direito à indenização da cobertura de diagnóstico de doenças graves apenas os titulares dos planos com desconto em folha de pagamento que possui ao menos de 65 (sessenta e cinco) anos na data do diagnóstico que estejam em plena atividade laboral. Proponentes com idade superior a 65 (sessenta e cinco) anos na data do diagnóstico ou aposentados por tempo de trabalho ou doença, independentemente da idade, não possuem direito a indenização.<br>
        Eventos cobertos: Tumor Maligno, Acidente Vascular Cerebral, Infarto do Miocárdio e Transplante de Órgãos Vitais, de acordo com condições gerais da apólice de seguro de vida em grupo vinculada ao contrato.<br>
        A falta de pagamento da mensalidade em prazo igual ou superior a 30 (trinta) dias corridos, seja por insuficiência de fundos, qualquer motivo informado pelo banco emissor do cartão de crédito ou inadimplência do boleto, acarretará em suspensão dos serviços ora ofertados, ensejando automaticamente em novos prazos de carência. Os dependentes extras com idade superior a 69 (sessenta e nove) anos serão automaticamente excluídos sem possibilidade de retorno.<br>
        A falta de repasse à Viva Max, pela entidade contratante, por prazo superior a 15 (quinze) dias do vencimento, acarretará em suspensão dos serviços ora ofertados, ensejando automaticamente e novos prazos de carência. Os dependentes extras com idade superior a 69 (sessenta e nove) anos serão automaticamente exclusos sem possibilidade de retorno.<br>
        Havendo desistência, exoneração de cargo público para contratos com desconto em folha de pagamento ou cancelamento do plano do titular por quaisquer motivos, automaticamente serão cancelados os planos dos proponentes extras a que se vinculam a mensalidade. <br>
        <br>
        <strong>DECLARAÇÃO DO PROPONENTE</strong><br>
        <br>
        Pelo presente, solicito minha inclusão e de meus beneficiários descritos no item 3  no Plano Familiar de Benefícios Viva Max, a qual é empresa estipulante da apólice de seguro que contempla o produto,  devidamente inscrita sob o CNPJ 20.504.980/00001-77, com sede da matriz no endereço Rua Marechal Cândido Rondon, 1818, Sala 12, bairro Centro, município de Cascavel, estado do Paraná, constituindo-o como meu/nosso mandatário junto às seguradoras, com poderes para representar junto as mesmas em futuras renovações,  negociações,  manutenção,  e continuidade da apólice de seguro a que se vinculam minhas coberturas, podendo inclusive alterar a atual seguradora,  bem como acertar com a mesma ou outras novos valores de prêmios de seguro, taxa e capitais segurados ainda que tais alterações contratuais venham a gerar ônus, deveres ou perdas de direito para minha pessoa ou meus beneficiários,  mas desde que visem garantir a sustentação técnica e a manutenção das demais cláusulas contratuais as quais ora são por mim aderidas.<br>
        Declaro que ao preencher a proposta de adesão tomei conhecimento na íntegra das condições contratuais do plano incluindo as condições gerais do seguro de vida em grupo contendo todas as cláusulas que foram integralmente lidas entendidas e aceitas por mim.<br>
        Declaro estar de acordo com a presente proposta e condições do plano e que nada omiti em relação ao meu estado de saúde ou de meus beneficiários vinculados ao meu plano, estando ciente do prazo de até 15 (quinze) dias úteis para aceitação tácita ou recusa da proposta tendo prestado todas as informações completas e verídicas, ficando à Viva Max autorizada a utilizar tais informações no amparo e defesa de seus direitos.<br>
        Estou ciente que de acordo com o artigo 766 do Código Civil brasileiro, se prestei declarações inexatas ou omiti informações e/ou circunstâncias que possam influir na aceitação da proposta perderei o direito às garantias contratuais, tornando-se nulo de pleno direito, esse contrato.<br>
        <br><br>
        <div class="row">
            <div class="col" style="text-align: center;">{{usuario?.municipio}} - {{usuario?.estado}}, {{usuario?.dataAdesao | date:'dd/MM/yyyy'}}</div>
        </div>
    </div>
    <br><br>
    <div class="row">
        <div class="col" style="text-align: center;line-height: 1.3em;">
            <span style="border-bottom:1px solid #000; width: 400px;">
                {{usuario?.vendedor}}
            </span><br>
                Representante
        </div>
    </div>
</div>
<!-- MODAL EDITAR DADOS --> 
 <div class="modalDados" id="modalDados" [ngStyle]="{'padding-left':app.isDesktop ? '20em' : '1em'}">
        <div class="boxEditar">
            <div class="mioloEditar">
                <h4>Edite os seus dados de contato</h4>
                <br>
                <div class="row formulario">
                    <div class="col col-6 col-md-4">
                        <label>Telefone:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="usuario.telefone">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Celular:</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.telefone.textMaskFunction}" [(ngModel)]="usuario.celular">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" [(ngModel)]="usuario.email" autocomplete="off"
                        (keyup)="app.checkEmail(usuario.email)" oninput="this.value = this.value.toUpperCase()">
                        <div class="errorMsg" *ngIf="usuario.email && !app.checkEmail(usuario.email)">E-mail inválido!</div>
                    </div>
                </div>
                <div class="row formulario">
                    <div class="col col-8 col-md-4">
                        <label>Rua:</label>
                        <input type="text" class="form-control" [(ngModel)]="usuario.rua" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-4 col-md-4">
                        <label>Nº:</label>
                        <input type="text" class="form-control" [(ngModel)]="usuario.numero" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>CEP</label>
                        <input type="text" class="form-control" [textMask]="{mask: MASKS.cep.textMask}" cep [(ngModel)]="usuario.cep">
                    </div>
                </div>
                <div class="row formulario">
                    <div class="col col-6 col-md-4">
                        <label>Bairro:</label>
                        <input type="text" class="form-control" [(ngModel)]="usuario.bairro" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Municipio:</label>
                        <ng-select class="form-control" [items]="cidades" bindLabel="cidade" appendTo="body" [(ngModel)]="municipio" (change)="changeMunicipio($event)"></ng-select>
                    </div>
                    <div class="col col-6 col-md-4">
                        <label>Complemento:</label>
                        <input type="text" class="form-control" [(ngModel)]="usuario.complemento" oninput="this.value = this.value.toUpperCase()">
                    </div>
                </div>
                <div style="height: 3em;"></div>
                <div class="row">
                    <div class="col" style="text-align: right;">
                        <button class="btn btn-dark" (click)="closeEditar()">CANCELAR</button>
                    </div>
                    <div class="col" style="text-align: left;">
                        <button class="btn btn-primary" (click)="salvar()">SALVAR</button>
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <div class="col">
                        <small>Para alterar outros detalhes do seu cadastro entre em contato pelos nossos canais de atendimento.</small>
                    </div>
                </div>
            </div>
        </div>
</div>
<!-- MODAL EDITAR DEPENDENTES -->
<div class="modalDependente" id="modalDependentes" [ngStyle]="{'padding-left':app.isDesktop ? '20em' : '1em'}">
    <div class="mioloEditar">
        <h4>Editar dependentes</h4>
        <div class="row alertaDependente">
            <div class="col">
                Poderá haver alteração no valor da mensalidade quando ocorrer inclusão ou exclusão de dependentes extras no serviço pós vida ou Telemedicina.
            </div>
        </div>
        <br>
        <div [hidden]="alterando">
            <label>Tipo de Alteração</label>
            <select class="form-control" [(ngModel)]="tipoAlteracaoDependente" [ngStyle]="{'border-color': !tipoAlteracaoDependente ? '#dd443e' : '#3cb42c'}">
                <option value="" selected disabled>Clique Aqui</option>
                <option value="INCLUSÃO">INCLUSÃO</option>
                <option value="ALTERAÇÃO">ALTERAÇÃO</option>
                <option value="EXCLUSÃO">EXCLUSÃO</option>
            </select>
        </div>
        <br><br>
        <div class="row" style="text-align: center;" [hidden]="tipoAlteracaoDependente">
            <div class="col">
                <button class="btn btn-dark" (click)="closeEditarDependentes()">CANCELAR</button>
            </div>
        </div>
        <div *ngIf="tipoAlteracaoDependente == 'INCLUSÃO'">
            <form [formGroup]="dependentes">
                <div class="row" formArrayName="dependente">
                    <div class="form-group col col-12 col-md-3" style="text-align: left;" [hidden]="listaDeDependentes?.length > 0">
                        <br>
                        <button class="btn btn-outline-success btn-sm" (click)="addDependente()" [hidden]="app.tipoEmpresa != 'VIVA MAX' && !app.hibrido && qtdeConectaDependentes() >= maxDependentesConecta">
                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon> ADICIONAR DEPENDENTE
                        </button>
                    </div>
                    <div class="form-group col col-12 col-md-9" [hidden]="listaDeDependentes?.length > 0">
                        &nbsp;
                    </div>
                    <div class="col col-12" *ngFor="let dep of dependentesFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row align-items-center">
                            <div class="form-group col col-12 col-md-3">
                                <label>Tipo:</label>
                                <select class="form-control" formControlName="tipo" (change)="selectTipoDependente(i)" id="tipo{{i}}" style="text-align: left;" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].tipo == '' || dependentes.controls.dependente.value[i].tipo == null ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="CÔNJUGE">CÔNJUGE</option>
                                    <option value="FILHO">FILHO</option>
                                    <option value="FILHA">FILHA</option>
                                    <option value="MÃE">MÃE</option>
                                    <option value="PAI">PAI</option>
                                    <option value="SOGRO">SOGRO</option>
                                    <option value="SOGRA">SOGRA</option>
                                    <option value="OUTROS" *ngIf="app.tipoEmpresa == 'CONECTA'">OUTROS</option>
                                </select>
                            </div>
                            <div class="form-group col col-12 col-md-6">
                                <label>Nome:</label>
                                <input type="text" class="form-control" formControlName="nome" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].nome == '' || dependentes.controls.dependente.value[i].nome == null ? '#dd443e' : null}">
                            </div>
                            <div class="form-group col col-6 col-md-3">
                                <label>Data Nascimento:</label>
                                <input type="text" class="form-control" (change)="verificaNascimento(i)" formControlName="nascimento" id="nascimento{{i}}" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].nascimento == '' || dependentes.controls.dependente.value[i].nascimento == null || maisSessentaNove ? '#dd443e' : null}">
                                <span style="font-size: 0.85em;color:red;" *ngIf="maisSessentaNove">IDADE MÁXIMA PERMITIDA DE 69 ANOS</span>
                            </div> 
                            <div class="form-group col col-6 col-md-3">
                                <label>CPF:</label>
                                <input type="text" class="form-control" formControlName="cpf" cpf  id="cpf{{i}}" (keyup)="app.checkCPF(dependentes.controls.dependente.value[i].cpf)" [textMask]="{mask: MASKS.cpf.textMask}" [ngStyle]="{'border-color': (dependentes.controls.dependente.value[i].conecta || dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO') && !app.checkCPF(dependentes.controls.dependente.value[i].cpf) ? '#dd443e' : null}">
                                <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].cpf && !app.checkCPF(dependentes.controls.dependente.value[i].cpf)">CPF incorreto!</div>
                            </div> 
                            <div class="form-group col col-6 col-md-5" [hidden]="app.tipoEmpresa != 'VIVA MAX' && !app.hibrido">
                                <label>ASF:</label>
                                <select class="form-control" formControlName="asf" id="asf{{i}}" (change)="selectAsf(dependentes.controls.dependente.value[i].tipo, dependentes.controls.dependente.value[i].asf,i)">
                                    <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                                    <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                                    <option value="NÃO HÁ FUNERAL CONTRATADO" [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE'">NÃO HÁ FUNERAL CONTRATADO</option>
                                    <option value="FUNERAL CONTRATADO" [disabled]="dependentes.controls.dependente.value[i].tipo == 'CÔNJUGE'">FUNERAL CONTRATADO</option>
                                </select>
                            </div>
                            <div class="form-group col col-6 col-md-3" *ngIf="app.tipoEmpresa == 'CONECTA'">
                                <label>E-mail:</label>
                                <input type="text" class="form-control" formControlName="email"
                                (keyup)="app.checkEmail(dependentes.controls.dependente.value[i].email)"
                                [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].conecta && ((dependentes.controls.dependente.value[i].email == '' || dependentes.controls.dependente.value[i].email == null || !app.checkEmail(dependentes.controls.dependente.value[i].email))) ? '#dd443e' : null}">
                                <div class="errorMsg" *ngIf="dependentes.controls.dependente.value[i].email && !app.checkEmail(dependentes.controls.dependente.value[i].email)">E-mail inválido!</div>
                            </div>
                            <div class="form-group col-6 col-md-3" *ngIf="app.tipoEmpresa == 'CONECTA'">
                                <label>Sexo:</label>
                                <select class="form-control" formControlName="sexo"
                                [ngStyle]="{'border-color': dependentes.controls.dependente.value[i].conecta && ((dependentes.controls.dependente.value[i].sexo == '' || dependentes.controls.dependente.value[i].sexo == null)) ? '#dd443e' : null}">
                                    <option value=""></option>
                                    <option value="M">MASCULINO</option>
                                    <option value="F">FEMININO</option>
                                </select>
                            </div>
                            <div class="form-group col col-6 col-md-3" *ngIf="app.tipoEmpresa == 'CONECTA' || app.hibrido">
                                <div class="checkCor">
                                    <label class="form-check-label" for="conectaCheck{{i}}">Telemedicina/Conecta</label><br>
                                    <input class="form-check-input" style="margin-top:25px" type="checkbox" id="conectaCheck{{i}}" formControlName="conecta" 
                                    [ngStyle]="{'pointer-events': (app.tipoEmpresa == 'CONECTA' && !app.hibrido) || (!dependentes.controls.dependente.value[i].conecta && (qtdeConectaDependentes() >= maxDependentesConecta)) ? 'none' : 'all'}"
                                     (change)="checkConecta($event,i)">
                                </div>
                            </div>
                            <div class="form-group col col-6 col-md-2">
                                <label>Valor:</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">R$</span>
                                    </div>
                                    <input type="number" class="form-control" formControlName="asfValor" id="asfValor{{i}}" style="pointer-events:none">
                                </div>
                            </div>
                            <div class="form-group col col-12 col-md-2" style="text-align: right;padding-bottom: 1em;">
                                <br>
                                <button class="btn btn-outline-danger" (click)="removeDependente(i)">
                                    <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                </button>&nbsp;&nbsp;
                                <button class="btn btn-outline-success" (click)="addDependente()"
                                [hidden]="app.tipoEmpresa != 'VIVA MAX' && !app.hibrido && qtdeConectaDependentes() >= maxDependentesConecta"
                                [disabled]="dependentes.invalid || (dependentes.controls.dependente.value[i].asf == 'FUNERAL CONTRATADO' && !dependentes.controls.dependente.value[i].cpf) || maisSessentaNove || (app?.hibrido && listaDeDependentes?.length >= 3) || (dependentes.controls.dependente.value[i].conecta && (!app.checkEmail(dependentes.controls.dependente.value[i].email) || !app.checkCPF(dependentes.controls.dependente.value[i].cpf)))">
                                    <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                </button>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>   
            </form>
            <br><br>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="cienteAdesao" [(ngModel)]="checkCienteAdesao" style="margin-top:0px !important">
                <label class="form-check-label" for="cienteAdesao" style="margin-left:10px">Estou ciente que o prazo de carência para inclusões é de 180 (cento e oitenta) dias a partir do aceite por parte da Viva Max.</label>
            </div>
            <br><br>
            <div class="row">
                <div class="col" style="text-align: right;">
                    <button class="btn btn-dark" (click)="closeEditarDependentes()">CANCELAR</button>
                </div>
                <div class="col" style="text-align: left;">
                    <button class="btn btn-primary" (click)="incluirDependentes()" [disabled]="maisSessentaNove || listaDeDependentes.value.length < 1 || dependentes.invalid || !checkCienteAdesao">INCLUIR</button>
                </div>
            </div>
        </div>
        <div *ngIf="tipoAlteracaoDependente == 'ALTERAÇÃO'">
            <div *ngIf="alterando">
                <div class="row">
                    <div class="col col-12 col-md-3">
                        <label>Tipo:</label>
                        <select class="form-control" style="text-align: left;" (change)="selectTipoDependenteEditar()" [(ngModel)]="tipoDependente" [ngStyle]="{'border-color': tipoDependente == '' || tipoDependente == null ? '#dd443e' : null}">
                            <option value=""></option>
                            <option value="CÔNJUGE">CÔNJUGE</option>
                            <option value="FILHO">FILHO</option>
                            <option value="FILHA">FILHA</option>
                            <option value="MÃE">MÃE</option>
                            <option value="PAI">PAI</option>
                            <option value="SOGRO">SOGRO</option>
                            <option value="SOGRA">SOGRA</option>
                            <option value="OUTROS" *ngIf="app.tipoEmpresa == 'CONECTA' || app.hibrido">OUTROS</option>
                        </select>
                    </div>
                    <div class="col col-12 col-md-6">
                        <label>Nome:</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeDependente" oninput="this.value = this.value.toUpperCase()" [ngStyle]="{'border-color': nomeDependente == '' || nomeDependente == null ? '#dd443e' : null}">
                    </div>
                    <div class="col col-6 col-md-3">
                        <label>Data Nascimento:</label>
                        <input type="text" class="form-control" (change)="verificaNascimentoEditar(nascimentoDependente)" [(ngModel)]="nascimentoDependente" maxlength="10" onkeyup="var v = this.value;if(v.match(/^\d{2}$/) !== null){this.value = v + '/';}else if(v.match(/^\d{2}\/\d{2}$/) !== null){this.value = v + '/';}" [ngStyle]="{'border-color': nascimentoDependente == '' || nascimentoDependente == null || nascimentoDependente.length < 10? '#dd443e' : null}">
                        <span style="font-size: 0.85em;color:red;" *ngIf="maisSessentaNoveEditar">IDADE MÁXIMA PERMITIDA DE 69 ANOS</span>
                    </div> 
                    <div class="col col-6 col-md-3">
                        <label>CPF:</label>
                        <input type="text" class="form-control" cpf [textMask]="{mask: MASKS.cpf.textMask}" (keyup)="app.checkCPF(cpfDependente)" [(ngModel)]="cpfDependente" [ngStyle]="{'border-color': (asfDependente == 'FUNERAL CONTRATADO' && !cpfDependente ) || cpfDependente?.indexOf('_') >= 0 ? '#dd443e' : null}">
                        <div class="errorMsg" *ngIf="cpfDependente && !app.checkCPF(cpfDependente)">CPF incorreto!</div>
                    </div>
                    <div class="col col-6 col-md-6" [hidden]="app.tipoEmpresa != 'VIVA MAX' && !app.hibrido">
                        <label>ASF:</label>
                        <select class="form-control" [(ngModel)]="asfDependente" (change)="selectAsfEditar()">
                            <option value="FUNERAL AUTOMÁTICO ATÉ 18 ANOS" disabled>FUNERAL AUTOMÁTICO ATÉ 18 ANOS</option>
                            <option value="FUNERAL AUTOMÁTICO VITALÍCIO" disabled>FUNERAL AUTOMÁTICO VITALÍCIO</option>
                            <option value="NÃO HÁ FUNERAL CONTRATADO" [disabled]="tipoDependente == 'CÔNJUGE'">NÃO HÁ FUNERAL CONTRATADO</option>
                            <option value="FUNERAL CONTRATADO" [disabled]="tipoDependente == 'CÔNJUGE'">FUNERAL CONTRATADO</option>
                        </select>
                    </div>
                    <div class="form-group col col-6 col-md-3" *ngIf="app.tipoEmpresa == 'CONECTA'">
                        <label>E-mail:</label>
                        <input type="text" class="form-control" [(ngModel)]="emailDependente"
                        (keyup)="app.checkEmail(emailDependente)"
                        [ngStyle]="{'border-color': conectaDependente && ((emailDependente == '' || emailDependente == null || !app.checkEmail(emailDependente))) ? '#dd443e' : null}">
                        <div class="errorMsg" *ngIf="emailDependente && !app.checkEmail(emailDependente)">E-mail inválido!</div>
                    </div>
                    <div class="form-group col-6 col-md-3" *ngIf="app.tipoEmpresa == 'CONECTA'">
                        <label>Sexo:</label>
                        <select class="form-control" [(ngModel)]="sexoDependente"
                        [ngStyle]="{'border-color': conectaDependente && ((sexoDependente == '' || sexoDependente == null)) ? '#dd443e' : null}">
                            <option value=""></option>
                            <option value="M">MASCULINO</option>
                            <option value="F">FEMININO</option>
                        </select>
                    </div>
                    <div class="form-group col col-6 col-md-3" *ngIf="app.hibrido">
                        <div class="checkCor">
                            <label class="form-check-label" for="conectaCheck">Telemedicina/Conecta</label><br>
                            <input class="form-check-input" style="margin-top:25px" type="checkbox" id="conectaCheck" [(ngModel)]="conectaDependente" (change)="checkConectaAlterar($event)"
                            [ngStyle]="{'pointer-events': (app.tipoEmpresa == 'CONECTA' && !app.hibrido) || (!conectaDependente && (qtdeConectaDependentes() >= maxDependentesConecta)) ? 'none' : 'all'}">
                        </div>
                    </div>
                    <div class="col col-6 col-md-3">
                        <label>Valor:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">R$</span>
                            </div>
                            <input type="number" class="form-control" id="asfValorDependente" [(ngModel)]="asfValorDependente" disabled>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="cienteAdesao" [(ngModel)]="checkCienteAdesao" style="margin-top:0px !important">
                    <label class="form-check-label" for="cienteAdesao" style="margin-left:10px">Estou ciente que o prazo de carência para inclusões é de 180 (cento e oitenta) dias a partir do aceite por parte da Viva Max.</label>
                </div>
                    <br><br>
            <div class="row">
                <div class="col" style="text-align: right;">
                    <button class="btn btn-dark" (click)="closeEditDependente()">CANCELAR</button>
                </div>
                <div class="col" style="text-align: left;">
                    <button class="btn btn-primary" (click)="alterarDependentes()" [disabled]="!checkCienteAdesao || maisSessentaNoveEditar || !tipoDependente || !nomeDependente || !nascimentoDependente || nascimentoDependente.length < 10 || (asfDependente == 'FUNERAL CONTRATADO' && !app.checkCPF(cpfDependente))">ALTERAR</button>
                </div>
            </div>
            </div>
            <div *ngIf="!alterando">
                <div class="row title">
                    <div class="col">
                        <label>Dependentes</label>
                    </div>
                </div>
                <div *ngFor="let dependenteEditar of usuario?.dependentes">
                    <div class="row item">
                        <div class="col col-6 col-md-4">
                            <strong>Nome:</strong><br> {{ dependenteEditar?.nome }}
                        </div>
                        <div class="col col-12 col-md-2">
                            <strong>CPF:</strong><br> {{ dependenteEditar?.cpf }}
                        </div>
                        <div class="col col-6 col-md-4">
                            <strong>Pós Vida:</strong><br> {{ dependenteEditar?.asf }}
                        </div>
                        <div class="col col-6 col-md-2" *ngIf="app.tipoEmpresa == 'CONECTA'">
                            <strong>Conecta:</strong><br> {{ dependenteEditar?.conecta ? 'SIM' : 'NÃO' }}
                        </div>
                    </div>
                    <div class="row item">
                        <div class="col col-6 col-md-4">
                            <strong>Data de nascimento:</strong><br> {{ dependenteEditar?.nascimento }}
                        </div>
                        <div class="col col-6 col-md-3">
                            <strong>Grau:</strong><br> {{ dependenteEditar?.tipo }}
                        </div>
                        <div class="col col-6 col-md-4">
                            <strong>Sinistro:</strong><br> {{ dependenteEditar?.sinistro?.situacao }}
                        </div>
                    </div>
                    <div class="row" style="text-align: left;">
                        <div class="col col-12 col-md-12">
                            <button class="btn btn-outline-primary" (click)="openEditDependentes(dependenteEditar)">
                                <fa-icon size="1x" [icon]="['fas', 'edit']"></fa-icon>EDITAR
                            </button>
                        </div>
                    </div>
                    <hr>
                </div>
                <br>   
                <div class="row" style="text-align: center;">
                    <div class="col col-12 col-md-12">
                        <button class="btn btn-dark" (click)="closeEditarDependentes()">CANCELAR ALTERAÇÃO</button>
                    </div>
                </div>
            </div>             
        </div>
        <div *ngIf="tipoAlteracaoDependente == 'EXCLUSÃO'">
            <div class="row title" *ngIf="usuario?.dependentes?.length > 1">
                <div class="col">
                    <label>Dependentes</label>
                </div>
            </div>
            <div *ngFor="let dependenteEditar of usuario?.dependentes">
                <div class="row">
                    <div class="col col-2" style="text-align: center;">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" (click)="selectDependentes($event,dependenteEditar)">
                        </div>
                    </div>
                    <div class="col col-10">
                        <div class="row item">
                            <div class="col col-6">
                                <strong>Nome:</strong><br> {{ dependenteEditar?.nome }}
                            </div>
                            <div class="col col-6">
                                <strong>CPF:</strong><br> {{ dependenteEditar?.cpf }}
                            </div>
                            <div class="col col-6">
                                <strong>Pós Vida:</strong><br> {{ dependenteEditar?.asf }}
                            </div>
                            <div class="col col-6">
                                <strong>Nascimento:</strong><br> {{ dependenteEditar?.nascimento }}
                            </div>
                            <div class="col col-6">
                                <strong>Grau:</strong><br> {{ dependenteEditar?.tipo }}
                            </div>
                            <div class="col col-6 col-md-3">
                                <strong>Sinistro:</strong><br> {{ dependenteEditar?.sinistro?.situacao }}
                            </div>
                            <div class="col col-6 col-md-3" *ngIf="app.tipoEmpresa == 'CONECTA'">
                                <strong>Conecta:</strong><br> {{ dependenteEditar?.conecta ? 'SIM' : 'NÃO' }}
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <br>
            <div class="row">
                <div class="col" style="text-align: right;">
                    <button class="btn btn-dark" (click)="closeEditarDependentes()">CANCELAR</button>
                </div>
                <div class="col" style="text-align: left;">
                    <button class="btn btn-primary" (click)="excluirDependentes()" [disabled]="dependentesExcluir.length < 1">EXCLUIR</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}" id="mainRight">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <div class="dados" [ngStyle]="{'width':app.isDesktop ? '65%' : '100%'}">
            <div style="width: 250px;">
                <div class="titulo">
                    Dados do Contrato
                </div>
                <div class="descricao">
                    Confira abaixo todas as informações importantes sobre o seu contrato conosco.
                </div>
            </div>
            <div style="height: 3em;"></div>
            <div class="row">
                <div class="col col-6 col-md-6">
                    <label>Contrato:</label>
                    <span *ngIf="usuario?.planoFisicoCheck"><p>Plano Físico</p></span><span *ngIf="!usuario?.planoFisicoCheck"><p>Plano Servidor</p></span>
                </div>
                <div class="col col-6 col-md-6">
                    <label>Sub Contrato:</label>
                    <p>{{ usuario?.subContrato }}</p>
                </div>
            </div>
            <div style="height: 3em;"></div>
            <div class="row" style="align-items: center;">
                <div class="col titulo">
                    <div style="line-height: 1.5em;">Dados Cadastrais</div>
                </div>
                <div class="col" style="text-align: left;">
                    <button class="btn btn-dark" (click)="openEditar()">Alterar</button>
                </div>
            </div>
            <div style="height: 3em;"></div>
            <div class="row">
                <div class="col col-6 col-md-4">
                    <label>Nome:</label>
                    <p>{{ usuario?.nome }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>CPF:</label>
                    <p>{{ usuario?.cpf }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Sexo:</label>
                    <p>{{ usuario?.sexo }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Data de Nascimento:</label>
                    <p>{{ usuario?.nascimento | date: 'dd/MM/yyyy' }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Estado Civil:</label>
                    <p>{{ usuario?.estadoCivil }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>E-mail:</label>
                    <p>{{ usuario?.email }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Telefone:</label>
                    <p>{{ usuario?.telefone }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Celular:</label>
                    <p>{{ usuario?.celular }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>CEP:</label>
                    <p>{{ usuario?.cep }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Rua:</label>
                    <p>{{ usuario?.rua }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Nº:</label>
                    <p>{{ usuario?.numero }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Complemento:</label>
                    <p>{{ usuario?.complemento }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Bairro:</label>
                    <p>{{ usuario?.bairro }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Municipio:</label>
                    <p>{{ usuario?.municipio }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Estado:</label>
                    <p>{{ usuario?.estado }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Local de Trabalho:</label>
                    <p>{{ usuario?.localTrabalho }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    <label>Função:</label>
                    <p>{{ usuario?.funcao }}</p>
                </div>
                <div class="col col-6 col-md-4">
                    &nbsp;
                </div>
            </div>
            <div style="height: 3em;"></div>
            <div class="row" style="align-items: center;" *ngIf="usuario?.dependentes?.length > 0">
                <div class="col titulo">
                    <div style="line-height: 1.5em;">Beneficiários</div>
                </div>
                <div class="col" style="text-align: left;">
                    <button class="btn btn-dark" (click)="openEditarDependentes()">Alterar</button>
                </div>
            </div>
            <div style="height: 3em;"></div>
            <div *ngFor="let dependente of usuario?.dependentes">
                <div class="row">
                    <div class="col">
                        <label>Nome:</label>
                        <p>{{ dependente?.nome }}</p>
                    </div>
                    <div class="col">
                        <label>CPF:</label>
                        <p>{{ dependente?.cpf }}</p>
                    </div>
                    <div class="col">
                        <label>Pós Vida:</label>
                        <p>{{ dependente?.asf }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Data de Nascimento:</label>
                        <p>{{ dependente?.nascimento }}</p>
                    </div>
                    <div class="col">
                        <label>Grau:</label>
                        <p>{{ dependente?.tipo }}</p>
                    </div>
                    <div class="col">
                        <label>Sinistro:</label>
                        <p>{{ dependente?.sinistro?.situacao }}</p>
                    </div>
                    <div class="col" *ngIf="app.tipoEmpresa == 'CONECTA'">
                        <strong>Conecta:</strong><br> {{ dependente?.conecta ? 'SIM' : 'NÃO' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="widget" *ngIf="app.isDesktop">
            <div class="infoVermelho">
                <div class="row">
                    <div class="col col-7">
                        <label>PLANO VIVA MAX</label>
                        <p>{{ usuario?.totalMensalidade | currency:'BRL' }}</p>
                    </div>
                    <div class="col col-5">
                        <label>ADESÃO:</label>
                        <p>{{ usuario?.dataFiliacao | date: 'dd/MM/yyyy' }}</p>
                    </div>
                </div>
            </div>
            <div style="height: 4em;"></div>
            <div class="infoAzul">
                <label>Cobertura do Plano</label>
                <div style="height: 3em;"></div>
                <p>Ampla rede médica e odontológica, farmácias, laboratórios e clínicas de estética credenciadas. Plano de benefícios válido para toda a família. <span *ngIf="!app.hibrido">Indenização de R$ 10.000,00 em caso de morte natural ou acidental do titular. Serviço Pós Vida Completo. Sorteio Mensal de {{ plano?.mensalidade > 60 ? 'R$ 10.000,00' : 'R$ 5.000,00'}}</span></p>
                <br>
                <label>Formas de pagamento</label>
                <div style="height: 3em;"></div>
                <div class="innerCard">
                    <div class="row">
                        <div class="col">
                            <p>DESCONTO EM FOLHA DE PAGAMENTO</p>
                        </div>
                    </div>
                </div>
                <div style="height: 3em;"></div>
                <div style="display: flex; justify-content: space-around;">
                    <button class="btn btn-dark" (click)="printProposta()">Baixar Contrato</button>
                    <button class="btn btn-dark" (click)="baixarClausulas()">Baixar Cláusulas</button>
                </div>
            </div>
        </div>
        <div style="height: 5em;"></div>
    </div>
    <div class="widgetMobile" *ngIf="!app.isDesktop">
        <div class="infoVermelho" style="margin:10px">
            <div class="row">
                <div class="col col-7">
                    <label>{{ usuario?.plano }}:</label>
                    <p>{{ usuario?.totalMensalidade | currency:'BRL' }}</p>
                </div>
                <div class="col col-5">
                    <label>ADESÃO:</label>
                    <p>{{ usuario?.dataAdesao | date: 'dd/MM/yyyy' }}</p>
                </div>
            </div>
        </div>
        <div style="height: 2em;"></div>
        <div class="infoAzul" style="margin:10px">
            <label>Cobertura do Plano</label>
            <div style="height: 3em;"></div>
            <p>Ampla rede médica e odontológica, farmácias, laboratórios e clínicas de estética credenciadas. Plano de benefícios válido para toda a família. Indenização de R$ 10.000,00 em caso de morte natural ou acidental do titular. Serviço Pós Vida Completo. Sorteio Mensal de R$ 5.000,00</p>
            <br>
            <label>Formas de pagamento</label>
            <div style="height: 3em;"></div>
            <div class="innerCard">
                <div class="row" *ngIf="usuario?.formaPagamento == 'DEBITO RECORRENTE'">
                    <div class="col">
                        <p><small>**** **** ****</small> {{ usuario?.numeroCartao?.slice(14,19) }}</p>
                        <p>{{ usuario?.validadeCartao }}</p>
                        <p>Débito recorrente</p>
                    </div>
                    <div class="col col-7" style="text-align:right">
                        <img src="../../assets/img/cartao-cielo.png" *ngIf="usuario?.cartaoSelecionado == 'CIELO'">
                        <img src="../../assets/img/cartao-master.png" *ngIf="usuario?.cartaoSelecionado == 'MASTERCARD'">
                        <img src="../../assets/img/cartao-elo.png" *ngIf="usuario?.cartaoSelecionado == 'ELO'">
                        <img src="../../assets/img/cartao-visa.png" *ngIf="usuario?.cartaoSelecionado == 'VISA'">
                        <img src="../../assets/img/cartao-american.png" *ngIf="usuario?.cartaoSelecionado == 'AMERICAN EXPRESS'">
                        <img src="../../assets/img/cartao-diners.png" *ngIf="usuario?.cartaoSelecionado == 'DINERS CLUB'">
                        <img src="../../assets/img/cartao-hipercard.png" *ngIf="usuario?.cartaoSelecionado == 'HIPERCARD'">
                        <br>
                        <p style="text-align:right;margin-top:2px">Mensalidade: {{ usuario?.totalMensalidade | currency: 'BRL'}}</p>
                    </div>
                </div>
                <div class="row" *ngIf="usuario?.formaPagamento != 'DEBITO RECORRENTE'">
                    <div class="col">
                        <p>{{ usuario?.formaPagamento }}</p>
                    </div>
                </div>
            </div>
            <div style="height: 3em;"></div>
            <div style="display: flex; justify-content: space-around;">
                <button class="btn btn-dark" (click)="printProposta()">Baixar Contrato</button>
                <button class="btn btn-dark" (click)="baixarClausulas()">Baixar Cláusulas</button>
            </div>
        </div>
        <div style="height: 5em;"></div>
    </div>
</div>