import { NgModule,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { InicioComponent } from './inicio/inicio.component';
import { CredenciadosComponent } from './credenciados/credenciados.component';
import { ContratoComponent } from './contrato/contrato.component';
import { FinanceiroComponent } from './financeiro/financeiro.component';
import { TelemedicinaComponent } from './telemedicina/telemedicina.component';
import { HistoricoComponent } from './historico/historico.component';
import { SorteioComponent } from './sorteio/sorteio.component';
import { CarteirinhasComponent } from './carteirinhas/carteirinhas.component';
import { AuthService } from './auth.service';
import { DataBaseService } from './provider.service';
import { FontAwesomeModule,FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { NgBrazil } from 'ng-brazil';
import { TextMaskModule } from 'angular2-text-mask';
import localePt from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormulariosComponent } from './formularios/formularios.component';
import { DragDropDirective } from './drag-drop.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatStepperModule } from '@angular/material/stepper';
import { EmpresaComponent } from './empresa/empresa.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AgendaComponent } from './agenda/agenda.component';
import { DxSchedulerModule } from 'devextreme-angular';
import { ServidorComponent } from './servidor/servidor.component';
import { ContratoServidorComponent } from './contrato-servidor/contrato-servidor.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InicioComponent,
    CredenciadosComponent,
    ContratoComponent,
    ContratoServidorComponent,
    HistoricoComponent,
    SorteioComponent,
    CarteirinhasComponent,
    FormulariosComponent,
    DragDropDirective,
    EmpresaComponent,
    FinanceiroComponent,
    AgendaComponent,
    TelemedicinaComponent,
    ServidorComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule, 
    TextMaskModule,
    NgBrazil,
    HttpClientModule,
    NgSelectModule,
    BrowserAnimationsModule,
    NgbModule,
    DxSchedulerModule,
    MatStepperModule,
    TabsModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    PdfJsViewerModule
  ],
  providers: [DataBaseService,AuthService,DeviceDetectorService,{
    provide: LOCALE_ID,
    useValue: "pt-BR"
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab);
   }
 }

 platformBrowserDynamic().bootstrapModule(AppModule);