<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <div class="row" style="text-align: center;">
            <div class="col col-12 col-md-5">
                <ng-select class="form-control" placeholder="Cidade" [items]="cidades" bindLabel="cidade" appendTo="body" [(ngModel)]="cidade" (change)="search()"></ng-select>
            </div>
            <div class="col col-12 col-md-6">
                <ng-select class="form-control" placeholder="Especialidade" [items]="especialidades" bindLabel="nome" appendTo="body" [(ngModel)]="especialidade" (change)="search()"></ng-select>
            </div>
        </div>
        <div style="text-align: center;font-weight: 700; margin: 10px;">
            - - - - - - - - - - - - -   ou   - - - - - - - - - - - - -
        </div>
        <div class="row">
            <div class="col col-10 col-md-10">
                <input type="text" class="form-control" placeholder="Nome credenciado" [(ngModel)]="nomeCredenciado">
            </div>
            <div class="col col-1 col-md-1" style="text-align:right">
                <button class="btn btn-primary" (click)="searchPorNome()">
                    <fa-icon [icon]="['fas', 'search']"></fa-icon>
                </button>
            </div>
        </div>
        <br>
        <div class="recipiente" [hidden]="credenciados.length < 1">
            <div class="card" *ngFor="let credenciado of credenciados" [ngStyle]="{'width': app.isDesktop ? '46%' : '100%'}">
                <div class="topoCard">
                    <div class="ribbon" *ngIf="diffDays(credenciado.dataContrato) <= 60">Novo</div>
                    <div class="logoCard">
                        <img src="../../assets/img/v.svg">
                    </div>
                    <label [ngStyle]="{'font-size': app.isDesktop ? '1.5em' : '1em'}">{{credenciado.fantasiaCredenciado}}</label>
                </div>
                <div class="innerCard" style="min-height:150px">
                    <div class="esquerda" [ngStyle]="{'font-size': app.isDesktop ? '1em' : '0.8em'}">
                        <p>{{credenciado.rua}}, {{credenciado.numero}},</p>
                        <p><span *ngIf="credenciado.complemento">{{credenciado.complemento}}<p></span>
                        <p>{{credenciado.bairro}}</p>
                        <p>{{credenciado.municipio}} - {{credenciado.estado}}</p>
                        <strong>
                            <p *ngIf="credenciado.telefoneExibir && !credenciado.telefone2Exibir">{{credenciado.telefone}}</p>
                            <p *ngIf="!credenciado.telefoneExibir && credenciado.telefone2Exibir">{{credenciado.telefone2}}</p>
                            <p *ngIf="credenciado.telefoneExibir && credenciado.telefone2Exibir">{{credenciado.telefone}} | {{credenciado.telefone2}}</p>
                        </strong>
                    </div>
                    <div class="direita" [ngStyle]="{'font-size': app.isDesktop ? '1em' : '0.8em'}">
                        <span *ngIf="credenciado.amic"><p>- REDE PARCEIRA AMIC</p></span>
                        <span *ngIf="credenciado.sindilojas"><p>- REDE PARCEIRA SINDILOJAS</p></span>
                        <span *ngIf="credenciado.guiaMedica"><p>- NECESSIDADE GUIA MÉDICA</p></span>
                        <p>{{ credenciado.observacoesCliente}}</p>
                        <p>PARA AGENDAMENTO DE CONSULTAS, EXAMES E VALORES POR GENTILEZA ENTRAR EM CONTATO COM NOSSA CENTRAL DE ATENDIMENTO.</p>  
                    </div>
                </div>
                <div class="innerCard">
                    <div class="esquerda" style="width:100%">
                        <button class="btn btn-outline-primary" style="width: 100%;" (click)="whatsCredenciado()"><fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>&nbsp;Agendar Consulta</button>
                    </div>
                </div>
            </div>
        </div>
    <div class="row" *ngIf="credenciados.length == 0 && procurado">
        <div class="col" class="nenhumResultado">
            <p>Nenhum Resultado Encontrado!</p>
        </div>
    </div>
    <div style="height: 4em;"></div>
    </div>
</div>
    
