<div class="dx-viewport" [ngStyle]="{'padding-left': app.isDesktop ? '20em' : '1em'}">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div [style.paddingTop]="app.isDesktop ? '25px' : '5em'"></div>
    <dx-scheduler
        [dataSource]="appointments"
        textExpr="title"
        allDayExpr="dayLong"
        recurrenceRuleExpr="recurrence"
        [(currentDate)]="dataHojeAgenda"
        [adaptivityEnabled]="false"
        (onAppointmentAdding)="addEvento($event)"
        (onAppointmentUpdating)="attEvento($event)"
        (onAppointmentDeleting)="delEvento($event)"
        (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
        [views]="['day', 'week', 'month', 'agenda']"
        currentView="month"
    ></dx-scheduler>
</div>