<div [ngStyle]="{'margin-left': app.isDesktop ? '20em' : '0px'}" id="rightCarteira">
    <nav class="fixed-top navbar bg-light" [hidden]="app.isDesktop">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/inicio']">
                <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
            </button>
        </div>
    </nav>
    <div class="boxed" [style.paddingTop]="app.isDesktop ? '25px' : '5em'">
        <p class="titulo">Sorteado do mês</p>
        <p class="descricao">Seu número da sorte é:</p>
        <div class="sorteioBox" style="padding:5px !important;margin:0px !important">
            <label>{{numSorte}}</label>
        </div>
        <div style="height:2em"></div>
        <div class="boxRoleta">
            <div class="sorteioBox" *ngFor="let sorteio of sorteios; let i = index" [ngClass]="i == 0 ? '' : 'opaco'" [ngStyle]="{'padding': app.isDesktop ? '50px' : '5px','margin':app.isDesktop ? '20px' : '0px','width':app.isDesktop ? '400px' : '100%','margin-bottom':app.isDesktop ? '0px' : '25px'}">
                <div class="svgContainer">
                    <img src="../../assets/img/vBlue.png">
                </div>
                <div>
                    <p>Número do conveniado ganhador do mês:</p>
                    <label>{{sorteio?.numero}}</label>
                    <p>{{sorteio?.data | date: 'MMMM'}} - {{sorteio.data | date: 'yyyy'}}</p>
                </div>
            </div>
        </div>
    </div>
</div>